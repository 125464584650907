import { useState, useEffect, Fragment } from "react";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/reducers/index";
import { sessionEnd, showSnackbar } from "../../redux/actions/index";

// MaterialUI
import { Card, Typography, Button, Alert } from "@mui/material";
import NavTab from "../../layout/NavTab";
import Box from "@mui/material/Box";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { styled } from "@mui/system";

// Interface
import { IMatriceUnit, MatriceWrapper } from "../../interface/matrice";

// React router
import { useNavigate } from "react-router-dom";

// Fetch
import { fetchPostProtect } from "../../fetchs/post";
import { getOne } from "../../fetchs/get";

// Components
import AddMatrixStep1 from "../../components/AddMatrix/AddMatrixStep1";
import AddMatrixStep2 from "../../components/AddMatrix/AddMatrixStep2";

// Tools
import useWindowDimensions from "../../tools/useWindowDimensions";

interface IFormValues {
  name: string;
  description: string;
  client: string[] | [] | [string] | [{ _id: string; name: string }];
}

const AddMatrix = () => {
  const [error, setError] = useState<string>("");
  const [masterMatrice, setMasterMatrice] = useState<MatriceWrapper>();
  const [step, setStep] = useState<number>(1);
  const [baseMatrix, setBaseMatrix] = useState<IFormValues>({
    name: "",
    description: "",
    client: [],
  });

  const dispatch = useDispatch();
  const token = useSelector((state: RootState) => {
    return state.user.token;
  });

  const navigate = useNavigate();

  const { width } = useWindowDimensions();
  let mobile: boolean = false;
  if (width) {
    mobile = width > 900 ? false : true;
  }

  // Gère le click bouton retour en arrière
  const handleBack = () => {
    step === 2 ? setStep(1) : navigate("/matrices");
  };

  const getMasterMatrice = () => {
    if (!token || masterMatrice !== undefined) return;
    getOne(token, "/matrice/master/", "project_master", setMasterMatrice, () => dispatch(sessionEnd()), setError);
  };

  useEffect(() => {
    getMasterMatrice();
  }, [masterMatrice]);

  /**
   * Enregistre les données de la première étape du formulaire (nom, description et clients) dans le state
   * @param values les valeurs du formulaire
   */
  const saveMatrixBaseData = async (values: IFormValues) => {
    setBaseMatrix({
      name: values.name,
      description: values.description,
      client: values.client,
    });
    setStep(2);
  };

  /**
   * Enregistre les données de la deuxième étape du formulaire (coûts/taux de chaque catégorie) dans le state
   * @param values les valeurs du formulaire
   */
  const saveMatrixComplete = async (values: [IMatriceUnit]) => {
    const body = {
      data: values,
      name: baseMatrix.name.trim(),
      desc: baseMatrix.description ? baseMatrix.description.trim() : "",
      clients: baseMatrix.client.map((client: any) => client._id),
    };

    if (token) {
      fetchPostProtect(token, "/matrice", JSON.stringify(body)).then((res) => {
        if (res.status === 200 || res.status === 201) {
          dispatch(showSnackbar("Matrice ajoutée avec succès", "success"));
          navigate("/matrices");
        } else {
          res.json().then((err) => {
            dispatch(showSnackbar("Erreur lors de l'ajout de la matrice : " + err.error, "error"));
          });
        }
      });
    }
  };

  const CenterBox = styled(Box)(({ theme }) => ({
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    itemsAlign: "center",
  }));

  return (
    <Fragment>
      <NavTab text="Ajouter une matrice" />
      <Card
        elevation={0}
        component="main"
        sx={{
          backgroundColor: "rgba(255,255,255,0.45)",
          m: 2,
          p: 2,
          borderRadius: "10px",
          height: "calc(100vh - 164px)",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box sx={{ display: "grid", gridTemplateColumns: "25% 50% 25%" }}>
          <Button
            variant="text"
            startIcon={<ArrowBackIcon />}
            sx={{
              width: "fit-content",
              height: "fit-content",
              px: 3,
            }}
            onClick={handleBack}
          >
            {!mobile && "Retour"}
          </Button>
          <CenterBox>
            <Typography variant="h6" component="div" align="center" sx={{ mt: step === 1 ? 1 : 0 }}>
              Paramétrage de la matrice
              <Typography variant="subtitle2" component="div" align="center" sx={{mb: 1}}>
                {baseMatrix?.name}
              </Typography>
            </Typography>
          </CenterBox>
          <Box
            sx={{
              width: "fit-content",
              height: "fit-content",
              justifySelf: "end",
              backgroundColor: "white",
              borderRadius: 4,
              mr: 1,
            }}
          >
            <Typography variant="subtitle2" component="div" align="center" sx={{ m: 2 }}>
              {!mobile && "Etape"} {step} / 2
            </Typography>
          </Box>
        </Box>

        {error ? <Alert severity="error">{error}</Alert> : null}
        {step === 1 ? (
          <Box
            sx={{
              height: "fit-content",
              width: "fit-content",
              border: "1px solid rgba(0,0,0,0.2)",
              borderRadius: "10px",
              padding: "30px",
              backgroundColor: "white",
              margin: "auto",
            }}
          >
            <Typography variant="subtitle2" component="div" align="center">
              Merci de renseigner les champs obligatoires pour l'ajout d'une matrice
            </Typography>
            <CenterBox>
              <AddMatrixStep1 defaultData={baseMatrix} handleSubmit={saveMatrixBaseData} />
            </CenterBox>
          </Box>
        ) : (
          <Box 
            sx={{
              height: "fit-content",
              width: "fit-content",
              overflow: "auto",
              borderRadius: "10px",
              margin: "auto",
            }}
          >
          <AddMatrixStep2 masterMatrix={masterMatrice!.data} handleSubmit={saveMatrixComplete} />
          </Box>
        )}
      </Card>
    </Fragment>
  );
};

export default AddMatrix;
