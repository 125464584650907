// Import MaterialUI
import { Box } from "@mui/system";
import { TextField, Button, Alert } from "@mui/material";

// Yup
import * as yup from "yup";

// Formik
import { useFormik } from "formik";

// fetch
import { fetchPost } from "../../fetchs/post";

const validationSchema = yup.object({
  firstname: yup
    .string()
    .trim()
    .min(3, "Votre prénom doit contenir au moins 3 lettres")
    .required("Votre prénom est obligatoire"),
  lastname: yup
    .string()
    .trim()
    .min(3, "Votre nom doit contenir au moins 3 lettres")
    .required("Votre nom est obligatoire"),
  email: yup
    .string()
    .trim()
    .email("Ce n'est pas une adresse email valide")
    .default("example@acteam-it.com")
    .matches(/^[a-z-]*.[a-z-]*@acteam-it.com$/, "Veuillez entrer une adresse e-mail valide appartenant à Acteam-It")
    .required("Votre email est obligatoire"),
  password: yup
    .string()
    .required("Le mot de passe est obligatoire")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      "Le mot de passe doit contenir au moins 8 caractères (dont au moins une majuscule, une minuscule, un caractère spécial et un nombre)."
    ),
  retypePassword: yup
    .string()
    .oneOf(
      [yup.ref("password"), undefined],
      "Les deux mots de passe doivent être identiques"
    )
    .required("Mot de passe obligatoire"),
});

interface IFormValues {
  firstname: string;
  lastname: string;
  email: string;
  password: string;
  retypePassword: string;
}

const Signup = ({
  setError,
  error,
  setOpenSuccess,
  setGotAccount,
}: {
  setError: (error: string) => void;
  error: string;
  setOpenSuccess: (openSuccess: boolean) => void;
  setGotAccount: (openSuccess: boolean) => void;
}) => {
  const signup = (values: IFormValues) => {
    const body = JSON.stringify({
      name: `${values.firstname} ${values.lastname}`,
      email: values.email,
      password: values.password,
    });
    fetchPost("/auth/signup", body)
      .then((response) => {
        if (response.status === 201) {
          response
            .json()
            .then((result) => {
              setOpenSuccess(true);
              setGotAccount(true);
            })
            .catch((err) => console.error(err));
        } else if (response.status === 409) {
          response.json().then((result) => {
            setError(result.message);
          });
        } else {
          setError("Une erreur est survenue lors de la création du compte");
        }
      })
      .catch((err) => console.error(err));
  };

  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      email: "",
      password: "",
      retypePassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values: IFormValues) => {
      setError("");
      signup(values);
    },
  });

  return (
    <Box component="form" onSubmit={formik.handleSubmit} sx={{ mt: 1 }}>
      <TextField
        margin="normal"
        required
        fullWidth
        name="firstname"
        value={formik.values.firstname}
        onChange={formik.handleChange}
        error={formik.touched.firstname && Boolean(formik.errors.firstname)}
        helperText={formik.touched.firstname && formik.errors.firstname}
        label="Prénom"
        type="firstname"
        id="firstname"
        autoComplete="current-firstname"
        autoFocus
      />
      <TextField
        margin="normal"
        required
        fullWidth
        name="lastname"
        value={formik.values.lastname}
        onChange={formik.handleChange}
        error={formik.touched.lastname && Boolean(formik.errors.lastname)}
        helperText={formik.touched.lastname && formik.errors.lastname}
        label="Nom"
        type="lastname"
        id="lastname"
        autoComplete="current-lastname"
      />
      <TextField
        margin="normal"
        required
        fullWidth
        value={formik.values.email}
        onChange={formik.handleChange}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={formik.touched.email && formik.errors.email}
        id="email"
        label="Email"
        placeholder="example@acteam-it.com"
        name="email"
        autoComplete="email"
      />
      <TextField
        margin="normal"
        required
        fullWidth
        name="password"
        value={formik.values.password}
        onChange={formik.handleChange}
        error={formik.touched.password && Boolean(formik.errors.password)}
        helperText={formik.touched.password && formik.errors.password}
        label="Mot de passe"
        type="password"
        id="password"
        autoComplete="current-password"
      />
      <TextField
        margin="normal"
        required
        fullWidth
        name="retypePassword"
        value={formik.values.retypePassword}
        onChange={formik.handleChange}
        error={formik.touched.retypePassword && Boolean(formik.errors.retypePassword)}
        helperText={formik.touched.retypePassword && formik.errors.retypePassword}
        label="Confirmer mot de passe"
        type="password"
        id="retapePassword"
        autoComplete="current-retapePassword"
      />

      {error ? <Alert severity="error">{error}</Alert> : null}
      <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
        Créer son compte
      </Button>
    </Box>
  );
};

export default Signup;
