// React
import { useState, Fragment } from "react";

// Material UI
import {
  Typography,
  Divider,
  Menu,
  Button,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Item from "@mui/material/ListItem";
import Fade from '@mui/material/Fade';

// Icons
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";

// Tools
import { handleFilterClick, handleFilterClose, handleFilterChange, handleSelectAll } from "../../../tools/history";

const EntityFilter = ({
  currentEntities,
  setCurrentEntities,
  entities,
}: {
  currentEntities: string[];
  setCurrentEntities: React.Dispatch<React.SetStateAction<string[]>>;
  entities: string[];
}) => {
  const [entityFilterAnchorEl, setEntityFilterAnchorEl] = useState<null | HTMLElement>(null);

  const entityFilterOpen = Boolean(entityFilterAnchorEl);

  return (
    <Fragment>
      <Menu
        id="person-filter"
        anchorEl={entityFilterAnchorEl}
        open={entityFilterOpen}
        onClose={handleFilterClose(setEntityFilterAnchorEl)}
        MenuListProps={{
          "aria-labelledby": "person-filter",
        }}
        TransitionComponent={Fade}
        PaperProps={{
            style: {
              maxHeight: 600,
              width: '25ch',
            },
          }}
      >
          <FormControlLabel
            control={
              <Checkbox
                checked={currentEntities.length === entities.length}
                indeterminate={currentEntities.length > 0 && currentEntities.length < entities.length}
                onChange={handleSelectAll(setCurrentEntities, entities, currentEntities)}
                size="small"
              />
            }
            label={<span style={{ fontWeight: 500 }}>Select All</span>}
            sx={{
                pl: 2,
                pb: .5,
            }}
          />

        <Divider sx={{mb: .5}} />

        {entities.map((entity) => (
          <MenuItem key={entity} dense style={{ padding: "0" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={currentEntities.includes(entity)}
                  onChange={handleFilterChange(setCurrentEntities, currentEntities)(entity)}
                  size="small"
                />
              }
              label={<span style={{ textTransform: "none" }}>{entity}</span>}
              sx={{
                pl: 5,
              }}
            />
          </MenuItem>
        ))}
        <Divider />
        <Button
          size="small"
          onClick={() => {
            setCurrentEntities([]);
          }}
          sx={{ ml: 2 }}
        >
          Clear ({currentEntities.length})
        </Button>
      </Menu>
      <Item sx={{ width: 120 }}>
        <Button
          id="person-filter"
          aria-controls="person-filter"
          aria-haspopup="true"
          aria-expanded={entityFilterOpen ? "true" : undefined}
          onClick={handleFilterClick(setEntityFilterAnchorEl)}
          endIcon={<ExpandMoreRoundedIcon />}
        >
          <Typography variant="subtitle2">Entité</Typography>
        </Button>
      </Item>
    </Fragment>
  );
};

export default EntityFilter;