import {
  SESSION_START,
  SESSION_END,
  ADD_TASK,
  CHANGE_VALUE,
  CALCULATE_VALUE,
  ADD_CHARGES,
  CALCUL_T_COSTS,
  DELETE_TASKS,
  CALCUL_ALL,
  GET_LOT_DATA,
  INIT_TASKS,
  INIT_CALCUL_COSTS,
  INIT_EMPTY_COSTS,
  SHOW_SNACKBAR,
  HIDE_SNACKBAR,
} from "./types";
import { ITask, ITaskCost } from "../../interface/task";

export const sessionStart = (token: string, username: string) => {
  return {
    type: SESSION_START,
    payload: { token, username },
  };
};

export const sessionEnd = () => {
  return {
    type: SESSION_END,
  };
};

export const initTasks = (tasks: Array<ITask>) => {
  return {
    type: INIT_TASKS,
    payload: { tasks },
  };
};

export const addTask = () => {
  return {
    type: ADD_TASK,
  };
};

export const addCharges = (costs: any) => {
  return {
    type: ADD_CHARGES,
    payload: { costs },
  };
};

export const changeValue = (value: any, name: string, id?: string | number) => {
  return {
    type: CHANGE_VALUE,
    payload: {
      value,
      name,
      id,
    },
  };
};

export const delete_tasks = (taskId: number, tasks: Array<ITask>) => {
  return {
    type: DELETE_TASKS,
    payload: {
      taskId,
      tasks,
    },
  };
};

export const calculateValue = (value: any, name: string, id: string | number) => {
  return {
    type: CALCULATE_VALUE,
    payload: {
      value,
      name,
      id,
    },
  };
};

export const calculTCosts = () => {
  return {
    type: CALCUL_T_COSTS,
  };
};

export const calculAll = () => {
  return {
    type: CALCUL_ALL,
  };
};

export const initCalculCost = () => {
  return {
    type: INIT_CALCUL_COSTS,
  };
};
export const initEmptyCosts = () => {
  return {
    type: INIT_EMPTY_COSTS,
  };
};

export const getLotData = (
  tasks: Array<ITask>,
  PPR: ITaskCost,
  tasksCharges: ITaskCost,
  totalCharges: ITaskCost,
  lotCosts: ITaskCost,
  total: number
) => {
  return {
    type: GET_LOT_DATA,
    payload: {
      tasks,
      PPR,
      tasksCharges,
      totalCharges,
      lotCosts,
      total,
    },
  };
};

export const showSnackbar = (message: string, severity: string) => ({
  type: SHOW_SNACKBAR,
  payload: {
    message,
    severity,
  },
});

export const hideSnackbar = () => ({
  type: HIDE_SNACKBAR,
});
