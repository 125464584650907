import { useState, useEffect } from "react";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/reducers/index";
import { sessionEnd } from "../../redux/actions/index";

// MaterialUI
import { Autocomplete, Box, TextField, Button, Alert, Typography } from "@mui/material";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";

// Fetch
import { getAll } from "../../fetchs/get";

//Yup
import * as yup from "yup";

// Formik
import { useFormik } from "formik";
import { IProject } from "../../interface/project";
import { IClient } from "../../interface/client";

// Color
import { colorOrange } from "../../config/color";

// Tools
import { updateDolibarrClients } from "../../tools/updateDolibarrClients";

// Types
import { MatriceWrapperClientsData } from "../../interface/matrice";

// Icons
import LibraryAddOutlinedIcon from "@mui/icons-material/LibraryAddOutlined";

interface IFormValues {
  name: string;
  description: string;
  client: string;
}

const validationSchema = yup.object({
  name: yup
    .string()
    .trim()
    .min(3, "Le nom du projet doit contenir au moins 3 caractères")
    .required("Le nom du projet est obligatoire"),
  description: yup
    .string()
    .trim()
    .min(8, "La description doit contenir au moins 8 caractères"),
  client: yup.string().required("Le client est obligatoire"),
});

/** Ce composant permet aussi bien d'ajouter des projets que de modifier les projets voir page AddProject + page Project + composant ProjectDetails
 *
 * @param project optionnel le projet à modifier si besoin
 * @param handleSubmit le fetch a envoyé sur le back
 * @returns un composant de modification ou ajout projet
 */
const AddUpdateProject = ({
  project,
  handleSubmit,
  edit,
}: {
  project?: IProject;
  handleSubmit: Function;
  edit: Boolean;
}) => {
  const [clients, setClients] = useState<any>([]);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [clientMatrices, setClientMatrices] = useState<any>([]);
  const [selectedMatriceID, setSelectedMatriceID] = useState<string>("");

  let dispatch = useDispatch();

  let token = useSelector((state: RootState) => {
    return state.user.token;
  });

  useEffect(() => {
    getAllClients();
  }, []);

  const getAllClients = () => {
    if (token) {
      getAll(
        token,
        "/clients/",
        setClients,
        () => dispatch(sessionEnd()),
        setError
      );
    }
  };

  const clientActeam = clients.map((client: IClient) => {
    return { _id: client._id, name: client.name };
  });

  // Utilisation de Formik et Yup pour gérer le formulaires
  const formik = useFormik({
    initialValues: {
      // Si c'est le composant modifation du projet, reprend les infos du projet existant
      name: project ? project.name : "",
      description: project ? project.description : "",
      client: project ? project.client._id : "",
    },
    validationSchema: validationSchema,
    onSubmit: (values: IFormValues) => {
      handleSubmit(values, selectedMatriceID);
    },
  });

  useEffect(() => {
    if (formik.values.client && token) {
      getAll(token, `/matrice/client/${formik.values.client}`, setClientMatrices, () => {
        dispatch(sessionEnd());
      });
    }
  }, [formik.values.client]);

  return (
    <Box component="form" onSubmit={formik.handleSubmit} sx={{ mt: 1, maxWidth: 500 }}>
      <TextField
        margin="normal"
        required
        fullWidth
        type="text"
        id="name"
        label="Nom du projet"
        name="name"
        autoComplete="name"
        value={formik.values.name}
        onChange={formik.handleChange}
        error={formik.touched.name && Boolean(formik.errors.name)}
        helperText={formik.touched.name && formik.errors.name}
        autoFocus
      />
      <TextField
        multiline
        minRows={3}
        fullWidth
        maxRows={6}
        type="textarea"
        autoComplete="description"
        label="Description du projet"
        value={formik.values.description}
        onChange={formik.handleChange}
        error={formik.touched.description && Boolean(formik.errors.description)}
        helperText={formik.touched.description && formik.errors.description}
        margin="normal"
        id="description"
        name="description"
      />
      <Autocomplete
        id="client"
        options={clientActeam}
        getOptionLabel={(option: any) => option.name}
        isOptionEqualToValue={(option, value) => option._id === value._id}
        fullWidth
        renderOption={(props, option) => {
          // Permet d'éviter que la key soit sur name et non id, il y a des doublons dans dolibarr
          return (
            <li {...props} key={option._id}>
              {option.name}
            </li>
          );
        }}
        defaultValue={project ? { _id: project.client._id, name: project.client.name } : null}
        onChange={(event, value) => {
          if (value) {
            formik.setFieldValue("client", value._id);
            setSelectedMatriceID("");
          } else {
            formik.setFieldValue("client", "");
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            name="client"
            margin="normal"
            label="Client"
            fullWidth
            error={formik.touched.client && Boolean(formik.errors.client)}
            helperText={formik.touched.client && formik.errors.client}
          />
        )}
      />
      {error ? <Alert severity="error">{error}</Alert> : null}
      {message ? <Alert severity="success">{message}</Alert> : null}

      <Button
        onClick={() => {
          updateDolibarrClients(token, setError, setMessage, setClients, dispatch);
        }}
        fullWidth
        sx={{ mt: 3, mb: 2, border: `1px solid ${colorOrange}` }}
      >
        {"Mettre à jour clients Dolibarr"}
      </Button>

      {!edit && (
        <Box sx={{ mt: 3, mb: 2 }}>
          <Typography variant="subtitle2" gutterBottom component="div">
            Matrice par défaut
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              flexWrap: "wrap",
            }}
          >
            <Chip
              label="Nouvelle"
              sx={{
                maxWidth: "200px",
                px: 1,
                m: 0.5,
              }}
              onClick={() => {
                setSelectedMatriceID("");
              }}
              icon={<LibraryAddOutlinedIcon />}
              variant={selectedMatriceID === "" ? "filled" : "outlined"}
              color="primary"
            />
            {clientMatrices.map((matrice: MatriceWrapperClientsData) => {
              return (
                <Tooltip title={matrice.desc} key={matrice._id} placement="top">
                  <Chip
                    label={matrice.name}
                    sx={{
                      maxWidth: "200px",
                      m: 0.5,
                    }}
                    onClick={() => {
                      setSelectedMatriceID(matrice._id);
                    }}
                    variant={selectedMatriceID === matrice._id ? "filled" : "outlined"}
                    color="primary"
                  />
                </Tooltip>
              );
            })}
          </Box>
        </Box>
      )}

      <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
        {project ? "Modifier le projet" : "Ajouter un projet"}
      </Button>
    </Box>
  );
};

export default AddUpdateProject;
