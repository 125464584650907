//MUI
import { Card } from "@mui/material";
import { Box } from "@mui/system";

//Image
import image from "../../img/maintenance2.png";

const TMA = () => {
    return (
      <Card
        component="main"
        sx={{
          backgroundColor: "white",
          flexGrow: 1,
          p: 2,
          m: 1,
          height: "100vh",
        }}
      >
        <Box
          component="div"
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            component="img"
            sx={{
              width: { xs: "100%", lg: "55%", xl: "50%" },
            }}
            alt="image maintenance en cours de création"
            src={image}
          />
        </Box>
      </Card>
    );
  };
export default TMA