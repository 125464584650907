// React imports
import { useState } from "react";

// Material UI
import { Chip, ChipProps } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { grey } from "@mui/material/colors";

// Material UI data grid imports
import {
  DataGrid,
  GridRowsProp,
  GridColDef,
  GridSortModel
} from "@mui/x-data-grid";

// Type imports
import { IHistory } from "../../interface/history";

// Tool imports
import {
  extractActionColor,
  extractCodeColor,
  extractActionIcon,
} from "../../tools/history";

// Component imports
import CustomNoRowsOverlay from "../../components/CustomNoRowsOverlay";

const DateChip: React.FC<ChipProps> = (props) => (
  <Chip
    {...props}
    sx={{
      root: {
        backgroundColor: grey[100],
      },
    }}
  />
);

const columns: GridColDef[] = [
  {
    field: "user",
    headerName: "Utilisateur",
    valueGetter: (params) => params.row.user.email,
    flex: 1,
    renderCell: (params) => (
      <Chip
        label={params.value}
        variant="filled"
        size="medium"
        avatar={<Avatar>{params.value.charAt(0)}</Avatar>}
      />
    ),
  },
  {
    field: "action",
    headerName: "Action",
    width: 180,
    valueGetter: (params) => params.row.action,
    renderCell: (params) => (
      <Chip
        label={params.value.charAt(0) + params.value.slice(1).toLowerCase()}
        color={extractActionColor(params.value)}
        icon={extractActionIcon(params.value)}
        sx={{ pl: 0.5, width: "125px" }}
        variant="outlined"
        size="medium"
      />
    ),
  },
  {
    field: "status",
    headerName: "Status",
    width: 130,
    valueGetter: (params) => params.row.status.code,
    renderCell: (params) => (
      <Chip
        label={params.value}
        color={extractCodeColor(params.value.toString())}
        variant="outlined"
        size="small"
      />
    ),
  },
  {
    field: "affectedEntity",
    headerName: "Entité affectée",
    valueGetter: (params) => params.row.affectedEntity.entityType,
    width: 170,
  },
  {
    field: "timestamp",
    headerName: "Date",
    width: 190,
    valueGetter: (params) => params.row.updatedAt,
    renderCell: (params) => (
      <DateChip
        label={new Date(params.value).toLocaleString()}
        variant="filled"
        size="small"
      />
    ),
  },
];

const HistoryGrid = ({
  historyData,
  ready,
  total,
  page,
  pageSize,
  setPageSize,
  setPage,
}: {
  historyData: IHistory[];
  ready: boolean;
  total: number;
  page: number;
  pageSize: number;
  setPageSize: (pageSize: number) => void;
  setPage: (page: number) => void;
}) => {
  
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "timestamp",
      sort: "desc",
    },
  ]);
  const rows: GridRowsProp = historyData;

  const [paginationModel, setPaginationModel] = useState({
    pageSize: pageSize,
    page: page - 1, // MUI utilise une indexation basée sur 0 pour les pages
  });

  const handlePaginationModelChange = (newModel:any) => {
    if (newModel.page !== paginationModel.page) {
      setPage(newModel.page + 1);
    }

    if (newModel.pageSize !== paginationModel.pageSize) {
      setPageSize(newModel.pageSize);
    }

    setPaginationModel(newModel);
  };

  return ready ? (
    <>
      <DataGrid
        paginationModel={paginationModel}
        onPaginationModelChange={handlePaginationModelChange}
        rows={rows}
        columns={columns}
        disableRowSelectionOnClick
        getRowId={(row) => row._id}
        sortModel={sortModel}
        onSortModelChange={(model) => setSortModel(model)}
        components={{
          NoRowsOverlay: CustomNoRowsOverlay,
        }}
        sx={{
          height: "100%",
        }}
        paginationMode="server"
        rowCount={total}
        pageSizeOptions={[10, 25, 50, 100]}
      />
    </>
  ) : null;
};

export default HistoryGrid;
