// Import Redux
import { useDispatch } from "react-redux";
import { sessionStart } from "../../redux/actions";

// Import React-router
import { useNavigate } from "react-router-dom";

// Formik
import { useFormik } from "formik";

// fetch
import { fetchPost } from "../../fetchs/post";

// Import MaterialUI
import { Box } from "@mui/system";
import { Alert } from "@mui/material";

interface IFormValues {
  email: string;
  password: string;
}

const LoginLocal = ({
  setError,
  error,
}: {
  setError: (error: string) => void;
  error: string;
}) => {
  const dispatch = useDispatch();

  let navigate = useNavigate();

  const connectLocal = (values: IFormValues) => {
    const body = JSON.stringify({
      email: values.email,
      password: values.password,
    });
    fetchPost("/auth/login", body)
      .then((response) => {
        if (response.status === 200) {
          response
            .json()
            .then((result) => {
              let token: string;
              token = result.token;
              let username: string = result.username;
              dispatch(sessionStart(token, username));
              navigate("/");
            })
            .catch((err) => console.error(err));
        } else if (response.status === 401) {
          setError("Vos informations de connexion sont erronés");
        }
      })
      .catch((err) => setError(err));
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: (values: IFormValues) => {
      connectLocal(values);
    },
  });
  return (
    <Box component="form" onSubmit={formik.handleSubmit} sx={{ mt: 1 }}>
      {error ? <Alert severity="error">{error}</Alert> : null}
    </Box>
  );
};

export default LoginLocal;
