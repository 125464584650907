import { Button } from "@mui/material";

const AddLotButton = ({
    setOpenModal
}: {
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>
}) => {
    return (
        <Button
            variant="contained"
            color="tertiary"
            sx={{ mr: 2 }}
            onClick={() => {
                setOpenModal(true);
            }}
        >
            Ajouter un lot
        </Button>
    )
}

export default AddLotButton