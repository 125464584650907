// React
import { useState, useEffect } from "react";

//MaterialUI
import { Box, Typography, List, Divider, Stack } from "@mui/material";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/reducers/index";
import { sessionEnd,showSnackbar } from "../../redux/actions/index";

//Fetch
import { getAll, getOne } from "../../fetchs/get";

// Interface
import { ILot } from "../../interface/lot";
import { MatriceWrapperClientsData, MatriceWrapper } from "../../interface/matrice";
import { IProject } from "../../interface/project";

// Icons
import LibraryAddOutlinedIcon from "@mui/icons-material/LibraryAddOutlined";

const MatrixTemplateSelect = ({
  selectedMatriceID,
  setSelectedMatriceID,
  setMatrice,
  projects,
  lot = null,
  setHasChanged,
}: {
  selectedMatriceID: string;
  setSelectedMatriceID: Function;
  setMatrice: Function;
  matrice: MatriceWrapperClientsData | MatriceWrapper | undefined;
  projects: IProject;
  lot?: ILot | null;
  setHasChanged: Function;
}) => {
  const [masterMatrice, setMasterMatrice] = useState<MatriceWrapper>();
  const [defaultProjectMatrice, setDefaultProjectMatrice] = useState<MatriceWrapperClientsData>();
  const [clientMatrices, setClientMatrices] = useState<any>([]);

  const dispatch = useDispatch();

  let token = useSelector((state: RootState) => {
    return state.user.token;
  });

  /** Obtenir la matrice master  */
  const getMasterMatrix = () => {
    if (!token) return;
    getOne(
      token,
      "/matrice/master/",
      "project_master",
      setMasterMatrice,
      () => dispatch(sessionEnd()),
      () => {
        console.log("error");
      }
    );
  };

  useEffect(() => {
    if (token && projects) {
      // Récupération de la matrice du lot ou par défaut du projet
      let matriceID = lot ? lot.matrice : projects.defaultMatrice;
      if (matriceID)
        getOne(token, "/matrice/id/", matriceID, setDefaultProjectMatrice, 
        () => {
          dispatch(sessionEnd())}, 
        (err: any) => { 
          console.error(`Error lors de la recuperation de la matrice du lot du projet ${projects.name}, ${err.error} `);
          dispatch( showSnackbar("Erreur : " + err?.error, "error"));
         }
        );

      // Récupération des matrices associées au client du projet
      getAll(token, `/matrice/client/${projects.client._id}`, setClientMatrices, () => {
        dispatch(sessionEnd());
      });
    }
  }, [projects]);

  // on set la matrice de la modal avec la matrice par défaut du projet ou du lot
  useEffect(() => {
    if (defaultProjectMatrice) {
      setMatrice(defaultProjectMatrice);

      setSelectedMatriceID(defaultProjectMatrice._id);
    }
  }, [defaultProjectMatrice]);

  useEffect(() => {
    getMasterMatrix();
  }, []);

  return (
    <Stack>
      <Typography component="p" sx={{ fontWeight: "bold", marginTop: 2 }}>
        Template de matrice
      </Typography>
      <Box sx={{ my: 2 }}>
        <List
          sx={{
            display: "flex",
            flexDirection: "row",
            padding: 0,
          }}
        >
          <Chip
            label="Nouvelle"
            sx={{
              maxWidth: "200px",
              px: 1,
              m: 0.5,
            }}
            onClick={() => {
              setSelectedMatriceID("");
              if (masterMatrice) setMatrice((prevMatrice: MatriceWrapperClientsData | MatriceWrapper) => {
                if (prevMatrice) {
                    return { ...prevMatrice, data: masterMatrice.data, basedOn: "" };
                }});

              setHasChanged(true);
            }}
            icon={<LibraryAddOutlinedIcon />}
            variant={selectedMatriceID === "" ? "filled" : "outlined"}
            color="primary"
          />
          <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
          <Tooltip title={defaultProjectMatrice?.desc ? defaultProjectMatrice?.desc : ""} placement="top">
            <Chip
              label={defaultProjectMatrice?.name}
              sx={{
                maxWidth: "200px",
                px: 1,
                m: 0.5,
              }}
              onClick={() => {
                if (defaultProjectMatrice) {
                  setSelectedMatriceID(defaultProjectMatrice._id);
                  setMatrice((prevMatrice: MatriceWrapperClientsData | MatriceWrapper) => {
                    if (prevMatrice) {
                        return { ...prevMatrice, data: defaultProjectMatrice.data, basedOn: defaultProjectMatrice._id };
                    }});
                    setHasChanged(true);

                }
              }}
              variant={selectedMatriceID === defaultProjectMatrice?._id ? "filled" : "outlined"}
              color="primary"
            />
          </Tooltip>

          <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
          {clientMatrices.map((matrice: MatriceWrapperClientsData) => {
            return (
              <Tooltip title={matrice.desc} key={matrice._id} placement="top">
                <Chip
                  label={matrice.name}
                  sx={{
                    maxWidth: "200px",
                    m: 0.5,
                  }}
                  onClick={() => {
                    setSelectedMatriceID(matrice._id);
                    setMatrice((prevMatrice: MatriceWrapperClientsData | MatriceWrapper) => {
                        if (prevMatrice) {
                            return { ...prevMatrice, data: matrice.data, basedOn: matrice._id };
                        }});

                    setHasChanged(true);
                  }}
                  variant={selectedMatriceID === matrice._id ? "filled" : "outlined"}
                  color="primary"
                />
              </Tooltip>
            );
          })}
        </List>
      </Box>
    </Stack>
  );
};

export default MatrixTemplateSelect;
