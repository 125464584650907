// React
import { useState, Fragment } from "react";

//MaterialUI
import { OutlinedInput } from "@mui/material";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Checkbox from "@mui/material/Checkbox";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";

// Interface
import { ICost, IMatriceUnit } from "../../interface/matrice";

// Tools
import useWindowDimensions from "../../tools/useWindowDimensions";

const hoverStyle = {
  "&:hover": {
    cursor: "pointer",
    backgroundColor: "#f5f5f5",
  },
};

export function MatrixModalRows({ row, handleChange }: { row: IMatriceUnit; handleChange: Function }) {
  const [open, setOpen] = useState<boolean>(false);
  const [error, setError] = useState("");

  const { width } = useWindowDimensions();

  /** Taux => nombre compris entre 0 et 100 */
  const rateNumber = (e: React.ChangeEvent<HTMLInputElement>, rowDetails: any) => {
  const parsedValue = parseFloat(e.target.value.replace(',', '.'));
  const roundedValue = Math.round(parsedValue);
  e.target.value = roundedValue.toString();
  if (row._id) {
      handleChange(e, rowDetails, row._id, "rate", setError, error);
  }
};

  return (
    <Fragment>
      <TableRow
        sx={[{ "& > *": { borderBottom: "unset" }, backgroundColor: open ? "#f5f5f5" : "#ffffff" }, hoverStyle]}
        onClick={() => setOpen(!open)}
      >
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell component="th" scope="row">
          {row.ref}
        </TableCell>
        <TableCell component="th" scope="row">
          { row.costs.map((rowDetails: ICost) =>
            rowDetails.isChoosen ? (rowDetails.rate ? rowDetails.rate + "%" : null) : null
          )}
        </TableCell>
        <TableCell component="th" scope="row">
          {row.costs.map((rowDetails: ICost) =>
            rowDetails.isChoosen ? (rowDetails.cost ? rowDetails.cost + "€" : null) : null
          )}
        </TableCell>
        {width && width > 600 ? (
          <TableCell component="th" scope="row">
            {row.calculationBase}
          </TableCell>
        ) : null}
      </TableRow>
      <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Details
              </Typography>
              <FormHelperText sx={{ color: "red" }}>{error ? error : null}</FormHelperText>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Niveau</TableCell>
                    {row.ref === "A" ? <TableCell>Nb jours d'acc</TableCell> : <TableCell>Taux en %</TableCell>}

                    <TableCell>Coût en €</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.costs.map((rowDetails: ICost) => (
                    <TableRow key={rowDetails.id}>
                      <TableCell>{rowDetails.level}</TableCell>

                      <TableCell>
                        {" "}
                        {row.ref === "RTUD" || row.ref === "RTUI" ? null : (
                          <OutlinedInput
                          id="rate"
                          name="rate"
                          defaultValue={rowDetails.rate ? rowDetails.rate : row.ref === "A" ? 1 : 0}
                          onBlur={(e: any) => {
                              rateNumber(e, rowDetails);
                          }}
                          onKeyDown={(e: any) => {
                              if (e.key === "Enter") {
                                  e.preventDefault();
                                  e.stopPropagation(); 
                                  const parsedValue = parseFloat(e.target.value.replace(',', '.'));
                                  const roundedValue = Math.round(parsedValue);
                                  e.target.value = roundedValue.toString();
                                  handleChange(e, rowDetails, row._id, "rate", setError);
                              }
                          }}
                          endAdornment={<InputAdornment position="end">{row.ref === "A" ? "" : "%"}</InputAdornment>}
                      />
                      
                        )}
                      </TableCell>
                      <TableCell>
                        {" "}
                        {row.ref === "PPR" || row.ref === "G" ? null : (
                          <OutlinedInput
                            id="cost"
                            name="cost"
                            defaultValue={rowDetails.cost ? rowDetails.cost : 0}
                            onBlur={(e: any) => {
                              handleChange(e, rowDetails, row._id, "cost", setError);
                            }}
                            onKeyDown={(e: any) => {
                              if (e.key === "Enter") {
                                handleChange(e, rowDetails, row._id, "cost", setError);
                              }
                            }}
                            endAdornment={<InputAdornment position="end">€</InputAdornment>}
                          />
                        )}
                      </TableCell>
                      <TableCell>
                        <Checkbox
                          color="primary"
                          checked={rowDetails.isChoosen ? rowDetails.isChoosen : false}
                          onChange={(e: any) => {
                            handleChange(e, rowDetails, row._id, "check", setError);
                          }}
                          onKeyDown={(e: any) => {
                            if (e.key === "Enter") {
                              handleChange(e, rowDetails, row._id, "check", setError);
                            }
                          }}
                          inputProps={{
                            "aria-label": "select all desserts",
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}
