import { combineReducers } from "redux";
import user from "./user";
import lotSynthesis from "./lotSyntesis";
import snackbarReducer from "./snackbar";

const rootReducer = combineReducers({ user: user, lotSynthesis: lotSynthesis, snackbar: snackbarReducer });

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
