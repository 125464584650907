// Import React-router
import { useLocation, Navigate } from "react-router-dom";

// Import Redux
import { useSelector } from "react-redux";

// Permet de typer le state de redux
import { RootState } from "../../redux/reducers/index";

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const isLogged = useSelector((state: RootState) => {
    return state.user.isLogged;
  });

  let location = useLocation();
  if (!isLogged && location.hash === "") {
    console.log("RequireAuth: user is not logged");
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

export default RequireAuth;