import { urlAPI } from "../config/config";

export const deleteOne = async (
  token: string,
  urlRouter: string,
  id: string = ""
) => {
  let apiEndPoint = `${urlAPI}${urlRouter}${id}`;

  const options = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      Authorization: "Bearer " + token,
    },
  };

  return fetch(apiEndPoint, options);
};

export const deleteOneLot = async (
  token: string,
  urlRouter: string,
  projectId: string = "",
  lotId: string = ""
) => {
  let apiEndPoint = `${urlAPI}${urlRouter}${projectId}${lotId}`;

  const options = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      Authorization: "Bearer " + token,
    },
  };

  return fetch(apiEndPoint, options);
};

export const deleteLotInLot = async (
  token: string,
  urlRouter: string,
  lotId: string = "",
) => {
  let apiEndPoint = `${urlAPI}${urlRouter}${lotId}`;
  const options = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      Authorization: "Bearer " + token,
    },
  };
  return fetch(apiEndPoint, options);
}

export const deleteOneMatrix = async (
    token: string,
    urlRouter: string,
    matrixID: string
) => {
    let apiEndPoint = `${urlAPI}${urlRouter}`;
    const options = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            Authorization: "Bearer " + token,
        },
        body: JSON.stringify({ _id : matrixID }),
    };
    return fetch(apiEndPoint, options);
}
