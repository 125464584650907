import { useState } from "react";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/reducers/index";
import { sessionEnd } from "../../redux/actions/index";

// MaterialUI
import { Card, Alert, Typography, Box } from "@mui/material";
import NavTab from "../../layout/NavTab";

// React router
import { useNavigate } from "react-router-dom";

// Fetch
import { fetchPostProtect } from "../../fetchs/post";

// Components
import AddUpdateProject from "../../components/Projects/AddUpdateProject";

interface IFormValues {
  name: string;
  description: string;
  client: string;
}

const AddProject = () => {
  const [error, setError] = useState<string>("");

  let dispatch = useDispatch();
  let navigate = useNavigate();

  // Permet de récupérer le token sauvegarder dans le redux
  let token = useSelector((state: RootState) => {
    return state.user.token;
  });

  // Permet de récupérer l'username sauvegarder dans le redux
  let author = useSelector((state: RootState) => {
    return state.user.username;
  });

  const addProject = async (values: IFormValues, matriceID: string = "") => {
    const body = JSON.stringify({
      name: values.name,
      description: values.description,
      client: values.client,
      author: author,
      defaultMatrice: matriceID,
    });
    if (token) {
      fetchPostProtect(token, "/projects/add", body)
        .then((response) => {
          if (response.status === 200) {
            setError("");
            response
              .json()
              .then((result) => {
                navigate(`/projet/${result._id}`, { state: "projectSuccess" });
              })
              .catch((err) => setError(err));
          } else if (response.status === 401) {
            dispatch(sessionEnd());
          } else {
            response.json().then((result) => setError(result.message));
          }
        })
        .catch((err) => setError(err));
    }
  };

  return (
    <div className="addProject">
      <NavTab text="Ajouter un projet" showDialog={true} />
      <Card
        elevation={0}
        component="main"
        sx={{
          backgroundColor: "rgba(255,255,255,0.45)",
          m: 2,
          p: 2,
          borderRadius: "10px",
          height: "auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          overflowY: 'auto'
        }}
      >
        <Box sx={{ height: "fit-content", width: "fit-content",
            border: "1px solid rgba(0,0,0,0.2)",
            borderRadius: "10px",
            padding: "30px",
            backgroundColor: "white",
        }}>
        <Typography variant="subtitle2" component="div" align="center">
            Merci de renseigner les champs pour l'ajout d'un projet
        </Typography>
        {error ? <Alert severity="error">{error}</Alert> : null}

        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "flex_start",
          }}
        >
          <AddUpdateProject handleSubmit={addProject} edit={false} />
        </div>
        </Box>
      </Card>
    </div>
  );
};

export default AddProject;
