// Icons
import AddCircleTwoToneIcon from "@mui/icons-material/AddCircleTwoTone";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import ContentCopyTwoToneIcon from "@mui/icons-material/ContentCopyTwoTone";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import QuestionMarkTwoToneIcon from "@mui/icons-material/QuestionMarkTwoTone";
import VpnKeyTwoToneIcon from '@mui/icons-material/VpnKeyTwoTone';
import PersonAddTwoToneIcon from '@mui/icons-material/PersonAddTwoTone';

// Types
import { IHistory } from "../interface/history";

export const extractCodeColor = (code: string) => {
  switch (code) {
    case "200":
    case "201":
      return "success";
    case "204":
    case "209":
      return "warning";
    case "404":
      return "error";
    case "500":
      return "error";
    default:
      return "error";
  }
};

export const extractCodeCategory = (code: string) => {
  const firstDigit = code.charAt(0);
  switch (firstDigit) {
    case "1":
      return "Réponses informatives";
    case "2":
      return "Réponses de succès";
    case "3":
      return "Redirections";
    case "4":
      return "Erreurs des clients";
    case "5":
      return "Erreurs des serveurs";
    default:
      return "Autres";
  }
};

export const extractActionColor = (action: string) => {
  switch (action) {
    case "Création":
    case "Connexion":
      return "success";
    case "Modification":
      return "warning";
    case "Duplication":
      return "info";
    case "Suppression":
      return "error";
    default:
      return "error";
  }
};

export const extractActionIcon = (action: string) => {
  switch (action) {
    case "Création":
      return <AddCircleTwoToneIcon fontSize="small" />;
    case "Modification":
      return <EditTwoToneIcon fontSize="small" />;
    case "Duplication":
      return <ContentCopyTwoToneIcon fontSize="small" />;
    case "Suppression":
      return <DeleteTwoToneIcon fontSize="small" />;
    case "Connexion":
      return <VpnKeyTwoToneIcon fontSize="small" />;
    case "Inscription":
      return <PersonAddTwoToneIcon fontSize="small" />;
    default:
      return <QuestionMarkTwoToneIcon fontSize="small" />;
  }
};

export const extractAllNames = (data: IHistory[]) => {
  const names: string[] = [];
  data.forEach((history) => {
    if (!names.includes(history.user.name)) {
      names.push(history.user.name);
    }
  });
  return names;
};

export const extractAllStatus = (data: IHistory[]) => {
  const status: string[] = [];
  data.forEach((history) => {
    if (!status.includes(history.status.code)) {
      status.push(history.status.code);
    }
  });
  return status;
};

export const extractAllEntities = (data: IHistory[]) => {
  const entities: string[] = [];
  data.forEach((history) => {
    if (!entities.includes(history.affectedEntity.name)) {
      entities.push(history.affectedEntity.name);
    }
  });
  return entities;
};

export const handleFilterClick =
  (setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>) => (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

export const handleFilterClose = (setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>) => () => {
  setAnchorEl(null);
};

export const handleFilterChange =
  (setFunc: React.Dispatch<React.SetStateAction<string[]>>, currentItems: string[]) =>
  (item: string) =>
  (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setFunc((oldArray) => (checked ? [...oldArray, item] : oldArray.filter((i) => i !== item)));
  };

export const handleSelectAll =
  (setFunc: React.Dispatch<React.SetStateAction<string[]>>, items: string[], currentItems: string[]) => () => {
    if (currentItems.length === items.length) {
      setFunc([]);
    } else {
      setFunc(items);
    }
  };
