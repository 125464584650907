// React
import { ChangeEvent } from "react";

// MaterialUI
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

// Yup
import * as yup from "yup";

// Interface
import {ICost, IMatriceUnit} from "../../interface/matrice";

// Redux
import {useDispatch} from "react-redux";
import {addCharges} from "../../redux/actions";

// Composant
import {matrixFormat} from "./MatrixFormat";
import {MatrixModalRows} from "./MatrixModalRows";
import useWindowDimensions from "../../tools/useWindowDimensions";

/**
 * Fonction de validation d'un nombre du champ "Taux en %" de la page de modification d'une matrice
 * @type {ObjectSchema<{value: string}>} - Schéma de validation
 */
const numberValidationRate = yup.object({
    value: yup
        .string()
        .transform((value, originalValue) => 
            typeof originalValue === 'string' ? originalValue.replace(',', '.') : originalValue
        )
        .required("Ce champ est obligatoire")
        .test('is-number', "Le nombre doit être positif ou nul", value => {
            const number = value ? Math.round(parseFloat(value)) : 0;
            return !isNaN(number) && number >= 0;
            
        })
        .test('max-three-digits-before-dot', "Le nombre doit avoir au maximum trois chiffres avant la virgule ou le point", value => {
            const [integerPart] = String(value).split('.');
            console.log('max-three-digits-before-dot test:', value, integerPart);
            return integerPart.length <= 3;
        }),
});


/**
 * Fonction de validation d'un nombre du champ "Coût en €" de la page de modification d'une matrice
 * @type {ObjectSchema<{value: string}>} - Schéma de validation
 */
const numberValidationCost = yup.object({
    value: yup
        .string()
        .transform((value, originalValue) => 
            typeof originalValue === 'string' ? originalValue.replace(',', '.') : originalValue
        )
        .required("Ce champ est obligatoire")
        .test('is-number', "Le nombre doit être positif ou nul", value => {
            const number = value ? parseFloat(value) : 0;
            return !isNaN(number) && number >= 0;
        }),
});

/** Composant permettant d'afficher le tableau des valeurs de la matrice et de les modifier */
export default function MatriceTable({
                                    lotProps,
                                    matrice,
                                    setMatrice,
                                    setErrorParent,
                                    selectedMatriceID,
                                }: {
    lotProps: any;
    matrice: Array<IMatriceUnit>;
    setMatrice: (matrice: IMatriceUnit[]) => void;
    setErrorParent: (error: string) => void;
    selectedMatriceID: string;
}) {
    const dispatch = useDispatch();
    const {width} = useWindowDimensions();
    
    /**
     * Permet de transformer un string en nombre et de verifier si ce nombre est valide à l'aide d'une fonction de validation
     * @param event Evenement de changement de valeur
     * @param setError Fonction permettant de mettre à jour la variable d'erreur
     * @param numberValidation Fonction de validation du nombre
     * @returns {Promise<{data: string, valid: boolean}>} Retourne un objet contenant le nombre et sa validité
     */
    const validateString = async (
        event: ChangeEvent<HTMLInputElement>,
        setError: Function,
        numberValidation: any
    ) => {
        let data = event.target.value;
        let result = numberValidation
            .validate({value: data})
            .then((valid: any) => {
                setError("");
                return {
                    data: valid.value,
                    valid: true,
                };
            })
            .catch((error: yup.ValidationError) => {
                setError(error.errors[0]);
                return {
                    data: error.errors[0],
                    valid: false,
                };
            });
        return result;
    };

    /**
     * Permet de mettre à jour la matrice en fonction de la valeur du champ "Taux en %", "Coût en €" ou de la checkbox
     * @param event Evenement de changement de valeur
     * @param item Objet de la matrice
     * @param rowId Id de la ligne de la matrice
     * @param type Type de champ à mettre à jour (check, rate, cost)
     * @param setError Fonction permettant de mettre à jour la variable d'erreur
     */
    const handleChange = async (
        event: ChangeEvent<HTMLInputElement>,
        item: ICost,
        rowId: number,
        type: string,
        setError: Function,
    ) => {
        let tmpMatrice = [...matrice];
        const index = tmpMatrice.findIndex((element) => element._id === String(rowId));
    
        for (const details of tmpMatrice[index].costs) {
            if (type === "check") {
                details.isChoosen = false;
                if (details.id === item.id) {
                    details.isChoosen = event.target.checked;
                }
            } else if (type === "rate" || type === "cost") {
                if (details.id === item.id) {
                    const validationFunction = type === "rate" ? numberValidationRate : numberValidationCost;
                    try {
                        const result = await validateString(event, setError, validationFunction);
                        if (result.valid) {
                            setErrorParent("");
                            const roundedValue = Math.round(parseFloat(result.data)); 
                            details[type] = roundedValue;
                        } else {
                            setErrorParent(result.data);
                        }
                    } catch (error) {
                        console.error("Error while validating:", error);
                    }
                }
            }
        }
    
        // Mettez à jour l'interface utilisateur une fois la boucle terminée
        setMatrice([...tmpMatrice]);
        const formatedTmpMatrice = matrixFormat(tmpMatrice);
        dispatch(addCharges(formatedTmpMatrice));
    }; 


    return (
        <div className="matrix_container">
            <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            <TableCell/>
                            <TableCell>Unité d'oeuvre</TableCell>
                            <TableCell>Ref</TableCell>
                            <TableCell>Taux</TableCell>
                            <TableCell>Coût</TableCell>
                            {width && width > 600 ? (
                                <TableCell>Assiette de Calcul</TableCell>
                            ) : null}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {matrice && matrice.map((row: IMatriceUnit) => (
                            <MatrixModalRows
                                key={row._id + selectedMatriceID}
                                row={row}
                                handleChange={handleChange}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}
