// SnackbarWrapper.tsx
import { SyntheticEvent, FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Snackbar, Alert } from "@mui/material";
import { hideSnackbar } from "../../redux/actions";

const SnackbarWrapper: FC = () => {
  const dispatch = useDispatch();
  const snackbar = useSelector((state: any) => state.snackbar);

  const handleClose = (event: SyntheticEvent<Element, Event> | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(hideSnackbar());
  };

  return (
    <Snackbar
      open={snackbar?.open}
      autoHideDuration={snackbar?.severity === "success" ? 3000 : 6000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={snackbar?.severity} sx={{ width: "100%" }}>
        {snackbar?.message}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarWrapper;
