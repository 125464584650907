import { useEffect } from "react";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { useNavigate } from "react-router-dom";

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    tertiary: true;
  }
}

export default function NavTab({
  text,
  // Le boolean search permet d'afficher ou non la barre de recherche
  search = false,
  token,
  setProject,
  setError,
  project,
  setOpenModal,
  setLotId,
  lotId,
  showDialog,
  displayReturn = false,
}: {
  text: string;
  search?: boolean;
  token?: string;
  setProject?: any;
  setError?: any;
  project?: any;
  setOpenModal?: Function;
  setLotId?: Function;
  lotId?: string;
  showDialog?: Boolean;
  displayReturn?: Boolean;
}) {
  let navigate = useNavigate();
  useEffect(() => {
    // logique à exécuter lorsque lotId ou project change
  }, [lotId, project]);
 
  const displayTitle = () => {
    return (
      <>
          {displayReturn && <ArrowBackIcon
            className="backToProjectArrow"
            sx={{ mr: 3 }}
            onClick={() => {
                if (lotId && setLotId) {
                  if (showDialog) {
                    navigate("/projet/" + project._id);
                  } else {
                    setLotId("");
                  }
                } else {
                  navigate("/projets/");
                }
              }}
          />}
        <Typography
          variant="h6"
          noWrap
          component="div"
          sx={{
            flexGrow: 1,
            display: { xs: "none", sm: "block" },
          }}
        >
          {text}
        </Typography>
      </>
    );
  };

  return (
    <div className="navDetails" style={{ height: "64px" }}>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar>
            {displayTitle()}
            {/*  si jamais lots est un tableau vide n'affiche pas le composant */}
            <Box sx={{ display: "flex", alignItems: "center" }} className="selectLots"> </Box>
          </Toolbar>
        </AppBar>
      </Box>
    </div>
  );
}
