import image from "../img/404-img.webp";

// Matérial UI
import { Box, Card } from "@mui/material";

const NotFound = () => {
  return (
    <Card
      component="main"
      sx={{
        backgroundColor: "white",
        flexGrow: 1,
        p: 2,
        m: 1,
        height: "100vh",
      }}
    >
      <Box
        component="div"
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          component="img"
          sx={{
            width: { xs: "100%", lg: "60%", xl: "50%" },
          }}
          alt="image 404 pas trouvé"
          src={image}
        />
      </Box>
    </Card>
  );
};

export default NotFound;
