import { createTheme } from '@mui/material/styles';
import { frFR } from '@mui/x-data-grid';
import { frFR as coreFrFR } from '@mui/material/locale';
import { colorDarkWhite, colorOrange } from './color';
import { colorDarkBrown } from './color';


// declare module '@mui/material/styles' {


export const theme = createTheme(
  {
    palette: {
      primary: {
        main: colorOrange,
        light: colorDarkWhite,
        contrastText: colorDarkBrown,
      },
      secondary: {
        main: '#ffffff',
        // main: '#f5f5f5',
      },
      tertiary: {
        main: colorDarkBrown,
        contrastText: colorDarkWhite,
      }
    }
  },
  frFR,
  coreFrFR
);
