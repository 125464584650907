// React
import { useState, memo } from "react";

// Form
import { useForm, Controller } from "react-hook-form";

// MaterialUI
import { Card, TextField, Typography, Button, Chip, Box } from "@mui/material";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Paper from "@mui/material/Paper";
import InputAdornment from "@mui/material/InputAdornment";
import Alert from "@mui/material/Alert";

// Interface
import { IMatriceUnit, ICost } from "../../interface/matrice";

/** Seconde étape de la création d'une matrice. Permet de renseigner les taux et coûts de chaque unité de la matrice. */
const AddMatrixStep2 = ({
  masterMatrix,
  handleSubmit,
}: {
  masterMatrix: Array<IMatriceUnit>;
  handleSubmit: Function;
}) => {
  const [matrice, setMatrice] = useState<Array<IMatriceUnit>>(masterMatrix);

  const {
    control,
    handleSubmit: handleHookFormSubmit,
    setValue,
    getValues,
    formState,
    watch,
    trigger,
  } = useForm({
    defaultValues: {
      matrice: matrice.map((item) => ({ ...item })),
    },
  });

  // Gestion de l'envoi du formulaire
  const onSubmit = (values: any) => {
    setMatrice(values.matrice);
    handleSubmit(values.matrice);
  };

  const sanitizeValue = (value: any) => {
    // Si la valeur est NaN ou null ou undefined, retourner 0
    return (typeof value === "number" && isNaN(value)) || value == null ? 0 : value;
  };

  const setRadioGroupValue = (matriceIndex: number, value: string) => {
    const currentCosts = getValues().matrice[matriceIndex].costs;

    const updatedCosts = currentCosts.map((cost) => {
      return {
        ...cost,
        isChoosen: cost.id === value,
      };
    });

    setValue(`matrice.${matriceIndex}.costs`, updatedCosts);
  };

  /**  Mise à jour de la valeur entrée dans un champ input de taux ou de coût.
   * On vérifie que la valeur entrée est un nombre et on la parse en float.
   * Si la valeur n'est pas un nombre, on la remplace par 0. */
  const updateInputValue = (matriceIndex: number, costIndex: number, value: string, field: "rate" | "cost") => {
    let valueParsed: number = Math.round(parseFloat(value));
    setValue(`matrice.${matriceIndex}.costs.${costIndex}.${field}`, valueParsed);
    trigger(`matrice.${matriceIndex}.costs`).then((isValid) => {
      if (!isValid) {
        setValue(`matrice.${matriceIndex}.costs.${costIndex}.${field}`, sanitizeValue(valueParsed));
      }
    });
  };

  // Fonctions de validation du formulaire
  // Présence de tout les taux
  const validateRate = (costs: Array<ICost>, ref: string) => {
    if (["PPR", "G", "RTUD", "RTUI", "A"].includes(ref)) {
      return true;
    } else {
      return costs.every((item: ICost) => item.rate !== undefined && !isNaN(item.rate))
        ? true
        : "Le taux est nécessaire";
    }
  };

  // Validité des taux
  const validateRateValidity = (costs: Array<ICost>, ref: string) => {
    if (["RTUD", "RTUI", "A"].includes(ref)) {
      return true;
    }
    return costs.every((item: ICost) => item.rate !== undefined && item.rate >= 0 && item.rate <= 100)
      ? true
      : "Le taux doit être un pourcentage valide (0-100)";
  };

  // Présence de tout les coûts
  const validateCost = (costs: Array<ICost>, ref: string) => {
    if (["PPR", "G"].includes(ref)) {
      return true;
    } else {
      return costs.every((item: ICost) => {
        return item.cost !== undefined && !isNaN(item.cost);
      })
        ? true
        : "Le coût est nécessaire";
    }
  };

  // Validité des coûts
  const validateCostValidity = (costs: Array<ICost>, ref: string) => {
    if (["PPR", "G"].includes(ref)) {
      return true;
    } else {
      return costs.every((item: ICost) => item.cost !== undefined && item.cost >= 0)
        ? true
        : "Le coût doit être un nombre positif";
    }
  };

  const CardRadioGroup = memo(({ matriceIndex }: { matriceIndex: number }) => {
    const selectedCostId = watch(`matrice.${matriceIndex}.costs`).find((cost: ICost) => cost.isChoosen)?.id;
    return (
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        name={`matrice[${matriceIndex}]`}
        value={selectedCostId}
        onChange={(event) => setRadioGroupValue(matriceIndex, event.target.value)}
        sx={{ height: 225 }}
      >
        {matrice[matriceIndex].costs.map((cost, costIndex) => {
          return (
            <FormControlLabel
              key={cost.id}
              value={cost.id}
              control={<Radio />}
              sx={{ m: 0 }}
              label={
                <Paper elevation={0} sx={{ display: "flex" }}>
                  {cost.rate !== undefined ? (
                    <TextField
                      name={`matrice.${matriceIndex}.costs.${costIndex}.rate`}
                      label="Pourcentage"
                      variant="outlined"
                      defaultValue={getValues().matrice[matriceIndex].costs[costIndex].rate}
                      onKeyDown={(event) => {
                        if (event.key === "Enter") {
                          event.preventDefault();
                          event.stopPropagation();
                          const target = event.target as HTMLInputElement;
                          updateInputValue(matriceIndex, costIndex, target.value, "rate");
                        }
                      }}
                      onBlur={(event) => updateInputValue(matriceIndex, costIndex, event.target.value, "rate")}
                      sx={{ m: 1 }}
                      InputProps={{
                        endAdornment: <InputAdornment position="start">%</InputAdornment>,
                      }}
                    />
                  ) : null}
                  {cost.cost !== undefined ? (
                    <TextField
                      name={`matrice.${matriceIndex}.costs.${costIndex}.cost`}
                      label="Coût"
                      variant="outlined"
                      defaultValue={getValues().matrice[matriceIndex].costs[costIndex].cost}
                      onKeyDown={(event) => {
                        if (event.key === "Enter") {
                          event.preventDefault();
                          event.stopPropagation();
                          const target = event.target as HTMLInputElement;
                          updateInputValue(matriceIndex, costIndex, target.value, "cost");
                        }
                      }}
                      onBlur={(event) => updateInputValue(matriceIndex, costIndex, event.target.value, "cost")}
                      sx={{ m: 1 }}
                      InputProps={{
                        endAdornment: <InputAdornment position="start">€</InputAdornment>,
                      }}
                    />
                  ) : null}
                </Paper>
              }
            />
          );
        })}
      </RadioGroup>
    );
  });

  const MatrixCard = memo(({ matriceElement, matriceIdx }: { matriceElement: IMatriceUnit; matriceIdx: number }) => {
    return (
      <Card
        elevation={0}
        sx={{
          width: "calc(25% - 16px)",
          "@media (max-width: 600px)": {
            width: "calc(100% - 16px)",
          },
          "@media (min-width: 600px) and (max-width: 1025px)": {
            width: "calc(50% - 16px)",
          },
          "@media (min-width: 1025px) and (max-width: 1350px)": {
            width: "calc(33% - 16px)",
          },
          height: "350px",
          m: 1,
          border: formState.errors.matrice?.[matriceIdx]
            ? "solid 2px rgba(239, 83, 80, 0.7)"
            : "1px solid rgba(0,0,0,0.1)",
          boxSizing: "border-box",
          borderRadius: "10px",
        }}
        key={matriceIdx}
      >
        <CardContent sx={{ height: "fit-content", width: "100%" }}>
          <Typography
            variant="h6"
            component="div"
            sx={{
              height: "80px",
              backgroundColor: "#ff9800",
              borderRadius: 2,
              px: 2,
              py: 1,
              width: "100%",
              display: "flex",
              alignItems: "center",
              boxShadow:
                "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
            }}
          >
            <Box sx={{ height: "fit-content" }}>
              <Chip label={matriceElement.ref} sx={{ height: 25, backgroundColor: "#ffab40", color: "white", mr: 1 }} />
              {matriceElement.name}
            </Box>
          </Typography>

          {formState.errors.matrice && formState.errors.matrice[matriceIdx]?.costs && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {formState.errors.matrice[matriceIdx]?.costs?.message}
            </Alert>
          )}
        </CardContent>

        <CardActions>
          <FormControl>
            {matriceElement.costs.length > 1 ? (
              <CardRadioGroup matriceIndex={matriceIdx} />
            ) : (
              <TextField
                id="outlined-basic"
                label="Pourcentage"
                variant="outlined"
                defaultValue={getValues().matrice[matriceIdx].costs[0].rate}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    event.preventDefault();
                    event.stopPropagation();
                    const target = event.target as HTMLInputElement;
                    setValue(`matrice.${matriceIdx}.costs.0.rate`, parseFloat(target.value));
                    trigger(`matrice.${matriceIdx}.costs`);
                  }
                }}
                onBlur={(event) => {
                  setValue(`matrice.${matriceIdx}.costs.0.rate`, parseFloat(event.target.value));
                  trigger(`matrice.${matriceIdx}.costs`);
                }}
                sx={{ mx: 1, width: 100, height: 225 }}
                InputProps={{
                  endAdornment: <InputAdornment position="start">%</InputAdornment>,
                }}
              />
            )}
          </FormControl>
        </CardActions>
      </Card>
    );
  });

  return (
    <form
      onSubmit={handleHookFormSubmit(onSubmit)}
      style={{
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        WebkitJustifyContent: "center",
        width: "100%",
        padding: 0,
        margin: 0,
      }}
    >
      {getValues().matrice.map((matriceElement, matriceIdx) => {
        return (
          <Controller
            key={matriceIdx}
            name={`matrice.${matriceIdx}.costs`}
            control={control}
            rules={{
              validate: {
                testRate: (costs) => validateRate(costs, getValues().matrice[matriceIdx].ref),
                testRateValidity: (costs) => validateRateValidity(costs, getValues().matrice[matriceIdx].ref),
                testCost: (costs) => validateCost(costs, getValues().matrice[matriceIdx].ref),
                testCostValidity: (costs) => validateCostValidity(costs, getValues().matrice[matriceIdx].ref),
              },
            }}
            render={() => <MatrixCard matriceElement={matriceElement} matriceIdx={matriceIdx} />}
          />
        );
      })}
      <div style={{ width: "100%", textAlign: "center" }}>
        <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2, width: 150 }}>
          Terminer
        </Button>
      </div>
    </form>
  );
};

export default AddMatrixStep2;
