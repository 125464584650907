import { urlAPI } from "../config/config";

export const fetchPutProtect = async (
  token: string,
  urlRouter: string,
  body: any
) => {
  let apiEndPoint = `${urlAPI}${urlRouter}`;

  const options = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      Authorization: "Bearer " + token,
    },
    body: body,
  };

  return fetch(apiEndPoint, options);
};

export const deleteOneTask = async (
  token: string,
  urlRouter: string,
  lotId: string = "",
  taskId = "",
) => {
  let apiEndPoint = `${urlAPI}${urlRouter}${lotId}${taskId}`;

  const options = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      Authorization: "Bearer " + token,
    },
  };

  return fetch(apiEndPoint, options);
};