// React
import { useState, useEffect, Fragment } from "react";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/reducers/index";
import { sessionEnd } from "../../redux/actions/index";

//MaterialUI
import {
  Dialog,
  IconButton,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Alert,
  Autocomplete,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Chip } from "@mui/material";

// Formik
import { IClient } from "../../interface/client";

// Fetch
import { getAll } from "../../fetchs/get";

// Color
import { colorOrange } from "../../config/color";

// Tools
import { updateDolibarrClients } from "../../tools/updateDolibarrClients";

/** Dialog pour éditer les informations de base d'une matrice (nom, description, clients) */
const MatrixMetadataDialogEdit = ({
  setOpenModal,
  formik,
  editClient = true,
}: {
  setOpenModal: Function;
  formik: any;
  editClient?: boolean;
}) => {
  const [clients, setClients] = useState<any>([]);
  const [error, setError] = useState<string>("");

  const dispatch = useDispatch();

  const token = useSelector((state: RootState) => {
    return state.user.token;
  });

  useEffect(() => {
    if (!clients.length) getAllClients();
    console.log(formik)
  }, []);

  const getAllClients = () => {
    if (token) {
      getAll(token, "/clients/", setClients, () => dispatch(sessionEnd()), setError);
    }
  };

  const clientActeam = clients.map((client: IClient) => {
    return { _id: client._id, name: client.name };
  });

  return (

    <Dialog
      open={true}
      keepMounted={false}
      onClose={() => setOpenModal(false)}
      aria-describedby="alert-dialog-slide-description"
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <DialogTitle>Informations de base de la matrice</DialogTitle>
        <IconButton
          onClick={() => {
            setOpenModal(false);
          }}
        >
          <CloseIcon />
        </IconButton>
      </div>

      <DialogContent>
        <Box component="form" onSubmit={formik.handleSubmit} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            type="text"
            id="name"
            label="Nom de la matrice"
            name="name"
            autoComplete="name"
            variant="outlined"
            defaultValue={formik.values.name}
            onBlur={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            autoFocus
          />
          <TextField
            multiline
            minRows={3}
            fullWidth
            maxRows={6}
            type="textarea"
            autoComplete="desc"
            label="Description de la matrice"
            variant="outlined"
            defaultValue={formik.values.desc}
            onBlur={formik.handleChange}
            error={formik.touched.des && Boolean(formik.errors.desc)}
            helperText={formik.touched.desc && formik.errors.desc}
            margin="normal"
            id="desc"
            name="desc"
          />
          {editClient && (
            <Fragment>
              <Autocomplete
                id="clients"
                options={clientActeam}
                multiple
                getOptionLabel={(option: any) => option.name}
                isOptionEqualToValue={(option, value) => option!._id === value!._id}
                fullWidth
                sx={{ maxWidth: 500 }}
                renderTags={(value, getTagProps) => {
                  if (value[0] === undefined) return null;
                  return value.map((option, index) => (
                    <Chip variant="outlined" label={option!.name} {...getTagProps({ index })} />
                  ));
                }}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option!._id}>
                      {option!.name}
                    </li>
                  );
                }}
                onChange={(event, value) => {
                  formik.setFieldValue("clients", value);
                }}
                value={formik.values.clients.map((client: any) =>
                  clientActeam.find((option: { _id: string; name: string }) => option!._id === client!._id)
                )}
                renderInput={(params) => (
                  <TextField {...params} name="clients" margin="normal" label={"Associé aux clients"} fullWidth />
                )}
              />
              {error ? <Alert severity="error">{error}</Alert> : null}

              <Button
                onClick={() => {
                  updateDolibarrClients(token, setError, () => {}, setClients, dispatch);
                }}
                fullWidth
                sx={{ mt: 3, mb: 2, border: `1px solid ${colorOrange}` }}
              >
                {"Mettre à jour clients Dolibarr"}
              </Button>
            </Fragment>
          )}

          <Button fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} onClick={() => setOpenModal(false)}>
            Continuer
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default MatrixMetadataDialogEdit;
