// Material UI
import { Button, Box, Chip, alpha } from "@mui/material";
import { styled } from "@mui/system";

// Icons
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

const CustomChip = styled(Chip)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.primary.main, 0.1),
  color: theme.palette.primary.main,
  "& .MuiChip-deleteIcon": {
    color: alpha(theme.palette.primary.main, 0.75),
  },
  fontWeight: 500,
}));

const SelectedFilters = ({
  currentNames,
  setCurrentNames,
  currentActions,
  setCurrentActions,
  currentStatus,
  setCurrentStatus,
  currentEntities,
  setCurrentEntities,
}: {
  currentNames: string[];
  setCurrentNames: React.Dispatch<React.SetStateAction<string[]>>;
  currentActions: string[];
  setCurrentActions: React.Dispatch<React.SetStateAction<string[]>>;
  currentStatus: string[];
  setCurrentStatus: React.Dispatch<React.SetStateAction<string[]>>;
  currentEntities: string[];
  setCurrentEntities: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  const hasFilters =
    currentNames.length > 0 ||
    currentActions.length > 0 ||
    currentStatus.length > 0 ||
    currentEntities.length > 0;

  return (
    // display all selected filters in chip with a cross to remove it
    hasFilters ? (
      <Box sx={{ display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "space-between", px: 2 }}>
        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
          {currentNames.map((name) => (
            <CustomChip
              key={name}
              label={name}
              onDelete={() => {
                setCurrentNames(currentNames.filter((currentName) => currentName !== name));
              }}
              deleteIcon={<CloseRoundedIcon />}
              sx={{ m: 0.5 }}
            />
          ))}
          {currentActions.map((action) => (
            <CustomChip
              key={action}
              label={action}
              onDelete={() => {
                setCurrentActions(currentActions.filter((currentAction) => currentAction !== action));
              }}
              deleteIcon={<CloseRoundedIcon />}
              sx={{ m: 0.5 }}
            />
          ))}
          {currentStatus.map((status) => (
            <CustomChip
              key={status}
              label={status}
              onDelete={() => {
                setCurrentStatus(currentStatus.filter((currentStatus) => currentStatus !== status));
              }}
              deleteIcon={<CloseRoundedIcon />}
              sx={{ m: 0.5 }}
            />
          ))}
          {currentEntities.map((entity) => (
            <CustomChip
              key={entity}
              label={entity}
              onDelete={() => {
                setCurrentEntities(currentEntities.filter((currentEntity) => currentEntity !== entity));
              }}
              deleteIcon={<CloseRoundedIcon />}
              sx={{ m: 0.5 }}
            />
          ))}
        </Box>
        <Button
          onClick={() => {
            setCurrentNames([]);
            setCurrentActions([]);
            setCurrentStatus([]);
            setCurrentEntities([]);
          }}
          sx={{ m: 0.5, textTransform: "none", textDecoration: "underline" }}
        >
          Clear All
        </Button>
      </Box>
    ) : (
      <></>
    )
  );
};

export default SelectedFilters;
