import { useEffect } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

// Interface
import { ILot } from "../../interface/lot";

// Menu de selection d'un lot dans un projet
const SelectLots = ({
  lots,
  setLotId,
  lotId,
}: {
  lots: Array<ILot>;
  setLotId: any;
  lotId: string;
}) => {
  useEffect(() => {
    if (lotId) setLotId(lotId);
  });

  const handleChange = (event: SelectChangeEvent) => {
    if (setLotId) {
      setLotId(event.target.value as string);
    }
  };

  return (
    <FormControl variant="standard" sx={{ mb: 1, mr: 2, minWidth: 120 }} size="small">
      <InputLabel id="inputLabelLots">Lots</InputLabel>
      <Select
        labelId="inputLabelLots"
        id="selectLots"
        value={lotId ? lotId : ""}
        label="Lots"
        onChange={handleChange}
      >
        {lots.map((lot) => {
          return (
            <MenuItem key={lot._id} value={lot._id ? lot._id : ""}>
              {lot.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

export default SelectLots;