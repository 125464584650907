// React
import { useState, useEffect } from "react";

// MaterialUI
import {
  Dialog,
  IconButton,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Button,
  Alert,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DialogActions from "@mui/material/DialogActions";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/reducers/index";
import {
  calculAll,
  calculTCosts,
  sessionEnd,
  showSnackbar,
} from "../../redux/actions/index";

// Fetch
import { getOne } from "../../fetchs/get";
import { fetchPostProtect } from "../../fetchs/post";

// Composant
import MatriceTable from "../Matrix/MatriceTable";
import MatrixTemplateSelect from "../ListMatrix/MatrixTemplateSelect";

// Yup
import * as yup from "yup";

// Formik
import { useFormik } from "formik";

// Interface
import { ILot } from "../../interface/lot";
import {
  IMatriceUnit,
  MatriceWrapperClientsData,
} from "../../interface/matrice";
import { IProject } from "../../interface/project";
import { useParams } from "react-router-dom";
import MatrixEditButtonStack from "../ListMatrix/MatrixEditButtonStack";

// Tools
import { useNavigate } from "react-router-dom";

const validationSchema = yup.object({
  name: yup
    .string()
    .trim()
    .min(3, "Le nom du lot doit avoir au moins 3 caractères")
    .required("Le nom du lot est obligatoire"),
});

interface IFormValues {
  name: string;
  desc?: string;
}

const AddLot = ({
  project,
  setProject,
  openModal,
  setOpenModal,
  setLotId,
  lot,
  setLot,
  lotId,
  syncLotMatrice
}: {
  project?: IProject;
  setProject?: Function;
  openModal: boolean;
  setOpenModal: Function;
  setLotId?: Function;
  lot?: ILot;
  setLot?: Function;
  lotId?: string;
  syncLotMatrice? : Function;
}) => {
  let navigate = useNavigate();
  const [error, setError] = useState("");
  const [matrice, setMatrice] = useState<MatriceWrapperClientsData>();
  const [projects, setProjects] = useState<IProject>();
  const [hasChanged, setHasChanged] = useState<boolean>(false);
  const [selectedMatriceID, setSelectedMatriceID] = useState<string>("");

  const dispatch = useDispatch();
  const params = useParams();

  let token = useSelector((state: RootState) => {
    return state.user.token;
  });

  let author = useSelector((state: RootState) => {
    return state.user.username;
  });

  /** Met à jour le champ data de la matrice
   * @param data nouvelles données de la matrice */
  const setMatriceData = (data: Array<IMatriceUnit>) => {
    if (matrice) {
      setMatrice({ ...matrice, data: data });
    }
    setHasChanged(true);
  };

  useEffect(() => {
    if (lot) {
      formik.setFieldValue("name", matrice?.name || "");
      formik.setFieldValue("desc", matrice?.desc || "");
    }
  }, [matrice]);

  /** Obtenir les données du projet  */
  const getProject = () => {
    if (!token) return;
    else if (params.projectId) {
      getOne(
        token,
        "/projects/",
        params.projectId,
        setProjects,
        () => dispatch(sessionEnd()),
        (err: any) => { 
          navigate(`/projets/`);
          dispatch(showSnackbar("Erreur : " + err?.error, "error"));
        }
      );
    }
  };

  const isAuthor = author === matrice?.creator;

  const createJSONBody = (bodyObject: any) => JSON.stringify(bodyObject);

  const handleResponse = (
    response: Response,
    successCallback: Function,
    errorCallback: Function
  ) => {
    if (response.status === 200 || response.status === 201) {
      response
        .json()
        .then((jsonResponse) => successCallback(jsonResponse))
        .catch((err: Error) => console.error(err));
    } else {
      errorCallback(response);
    }
  };

  const handleChange = (lot: any) => {
    if (setLotId) {
      setLotId(lot.id);
    }
  };

  const addData = async (values: IFormValues) => {
    if (!token) return;
    if (setLotId && matrice) {
      const bodyAddLot = createJSONBody({
        name: values.name.trim(),
        author: author,
        matrice: matrice.data,
        projectId: project?._id,
        basedOn: selectedMatriceID,
      });

      // Ajout du lot dans la base de données
      fetchPostProtect(token, "/lots/add", bodyAddLot)
        .then((response) =>
          handleResponse(
            response,
            (result: any) => {
              dispatch(showSnackbar("Le lot a bien été ajouté", "success"));
              if (setProject)
                setProject((project: IProject) => ({
                  ...project,
                  lots: [...project.lots, result.message],
                }));
              if (lotId) setLotId(result.message._id);
              setMatrice(undefined);
              values.name = "";
              setOpenModal(false);
              setLotId(result.message._id);
            },
            (response: any) => {
              if (response.status === 401) {
                dispatch(sessionEnd());
              } else {
                setError("Il y a un souci avec l'ajout de votre lot");
                response.json().then((err: any) => {
                  dispatch(
                    showSnackbar(
                      "Erreur lors de l'ajout : " + err.error,
                      "error"
                    )
                  );
                });
              }
            }
          )
        )
        .catch((err: Error) => console.error(err));
    } else if (lot && projects) {
      const matriceBody = {
        ...matrice,
        name: values?.name ? values.name.trim() : matrice?.name.trim(),
        desc: values?.desc ? values.desc.trim() : matrice?.desc.trim(),
      };

      // Mise à jour de la matrice du lot dans la base de données
      fetchPostProtect(
        token,
        "/matrice/update",
        JSON.stringify(matriceBody)
      ).then((response) =>
        handleResponse(
          response,
          () => {
            if (lot && setLot) {
              setLot(lot);
            }
            dispatch(
              showSnackbar("La matrice a bien été mise à jour", "success")
            );
            setOpenModal(false);
          },
          (response: any) => {
            if (response.status === 401) {
              dispatch(sessionEnd());
            } else {
              response.json().then((err: any) => {
                setError(err.error);
                dispatch(
                  showSnackbar(
                    "Erreur lors de la mise a jour : " + err.error,
                    "error"
                  )
                );
              });
            }
          }
        )
      );
    }
  };

  // on récupère les données du projet à chaque ouverture de la modal
  useEffect(() => {
    formik.resetForm();
    if (openModal) getProject();
    setHasChanged(false);
  }, [openModal]);

  // Calcul des coûts dans le redux
  const calcCostsFunc = () => {
    dispatch(calculAll());
    dispatch(calculTCosts());
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      desc: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values: IFormValues) => {
      addData(values);
      calcCostsFunc();
      setOpenModal(false);
    },
  });

  return (
    <Dialog
      open={openModal}
      keepMounted={false}
      onClose={() => setOpenModal(false)}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      fullWidth={true}
      maxWidth="xl"
      scroll="paper"
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          formik.submitForm();
          event.stopPropagation();
        }
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0 10px",
        }}
        id="scroll-dialog-title"
      >
        <DialogTitle>
          {lot ? "Matrice du lot" : "Création d'un lot"}
        </DialogTitle>
        <IconButton
          onClick={() => {
            if(syncLotMatrice) syncLotMatrice();
            setOpenModal(false);
          }}
        >
          <CloseIcon />
        </IconButton>
      </div>

      <DialogContent dividers={true} id="scroll-dialog-description">
        <form onSubmit={formik.handleSubmit}>
          {!lot ? (
            <TextField
              variant="outlined"
              required
              type="text"
              id="name"
              label={"Nom du lot"}
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              name="name"
              autoComplete="name"
              autoFocus
            />
          ) : matrice ? (
            <MatrixEditButtonStack
              // isAuthor={isAuthor}
              formik={formik}
              matrice={matrice}
              setMatrice={setMatrice}
              editClient={false}
              hasChanged={hasChanged}
            />
          ) : null}
          {projects && (
            <MatrixTemplateSelect
              selectedMatriceID={selectedMatriceID}
              setSelectedMatriceID={setSelectedMatriceID}
              setMatrice={setMatrice}
              matrice={matrice}
              projects={projects}
              lot={lot}
              setHasChanged={setHasChanged}
            ></MatrixTemplateSelect>
          )}

          <Typography component="p" sx={{ fontWeight: "bold", my: 2 }}>
            Vous pouvez modifier la matrice :
          </Typography>

          {matrice && (
            <MatriceTable
              lotProps={lot}
              matrice={matrice?.data}
              setMatrice={setMatriceData}
              setErrorParent={setError}
              selectedMatriceID={selectedMatriceID}
            />
          )}

          {error ? <Alert severity="error">{error}</Alert> : null}
          <DialogActions sx={{ m: 1 }}>
            <Button variant="outlined" onClick={() => {
              if(syncLotMatrice) syncLotMatrice();
              setOpenModal(false);
            }}>
              Annuler
            </Button>
            <Button variant="contained" type="submit" disabled={error !== ""} onClick={() => {if(syncLotMatrice) syncLotMatrice()}} >
              Valider
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AddLot;
