import { useState, useEffect } from "react";

// Redux
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers/index";
import { useDispatch } from "react-redux";
import { sessionEnd } from "../../redux/actions/index";

// MaterialUI
import { Autocomplete, Box, TextField, Button, Alert } from "@mui/material";
import { Chip } from "@mui/material";

// Fetch
import { getAll } from "../../fetchs/get";

//Yup
import * as yup from "yup";

// Formik
import { useFormik } from "formik";
import { IClient } from "../../interface/client";

// Color
import { colorOrange } from "../../config/color";

// Tools
import { updateDolibarrClients } from "../../tools/updateDolibarrClients";

const validationSchema = yup.object({
  name: yup
    .string()
    .trim()
    .min(3, "Le nom de la matrice doit contenir au moins 3 caractères")
    .required("Le nom de la matrice est obligatoire"),
  description: yup
    .string()
    .trim()
    .min(8, "La description doit contenir au moins 8 caractères"),
  client: yup.array(),
});

interface IFormValues {
  name: string;
  description: string;
  client: string[] | [] | [string] | [{ _id: string; name: string }];
}

/** Première étape de la création d'une matrice : nom, description et client */
const AddMatrixStep1 = ({ defaultData, handleSubmit }: { defaultData: IFormValues; handleSubmit: Function }) => {
  const [clients, setClients] = useState<any>([]);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const dispatch = useDispatch();

  const token = useSelector((state: RootState) => {
    return state.user.token;
  });

  useEffect(() => {
    getAllClients();
  }, []);

  const getAllClients = () => {
    if (token) {
      getAll(token, "/clients/", setClients, () => dispatch(sessionEnd()), setError);
    }
  };

  // On transforme le tableau de clients en tableau d'objets pour l'autocomplete
  const clientActeam = clients.map((client: IClient) => {
    return { _id: client._id, name: client.name };
  });

  // Utilisation de Formik et Yup pour gérer le formulaire
  const formik = useFormik({
    initialValues: {
      name: defaultData.name,
      description: defaultData.description,
      client: defaultData.client[0] === "" ? [] : defaultData.client,
    },
    validationSchema: validationSchema,
    onSubmit: (values: IFormValues) => {
      values.name = values.name.trim();
      values.description = values.description ? values.description.trim() : "";

      handleSubmit(values);
    },
  });

  return (
    <Box component="form" onSubmit={formik.handleSubmit} sx={{ mt: 1 }}>
      <TextField
        margin="normal"
        required
        fullWidth
        type="text"
        id="name"
        label="Nom de la matrice"
        name="name"
        autoComplete="name"
        value={formik.values.name}
        onChange={formik.handleChange}
        error={formik.touched.name && Boolean(formik.errors.name)}
        helperText={formik.touched.name && formik.errors.name}
        autoFocus
      />
      <TextField
        multiline
        minRows={3}
        fullWidth
        maxRows={6}
        type="textarea"
        autoComplete="description"
        label="Description de la matrice"
        value={formik.values.description}
        onChange={formik.handleChange}
        error={formik.touched.description && Boolean(formik.errors.description)}
        helperText={formik.touched.description && formik.errors.description}
        margin="normal"
        id="description"
        name="description"
      />
      <Autocomplete
        id="clients"
        options={clientActeam}
        multiple
        getOptionLabel={(option: any) => option.name}
        isOptionEqualToValue={(option, value) => option._id === value._id}
        fullWidth
        sx={{ maxWidth: 500 }}
        renderTags={(value, getTagProps) => {
          if (value[0] === undefined) return null;
          return value.map((option, index, key) => (
            <Chip variant="outlined" label={option.name} {...getTagProps({ index })} />
          ));
        }}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option._id}>
              {option.name}
            </li>
          );
        }}
        onChange={(event, value) => {
          formik.setFieldValue("client", value);
        }}
        value={formik.values.client.map((client: any) => clientActeam.find((option: any) => option._id === client._id))}
        renderInput={(params) => (
          <TextField {...params} name="clients" margin="normal" label={"Associé aux clients"} fullWidth />
        )}
      />
      {error ? <Alert severity="error">{error}</Alert> : null}
      {message ? <Alert severity="success">{message}</Alert> : null}

      <Button
        onClick={() => {
          updateDolibarrClients(token, setError, setMessage, setClients, dispatch);
        }}
        fullWidth
        sx={{ mt: 3, mb: 2, border: `1px solid ${colorOrange}` }}
      >
        {"Mettre à jour clients Dolibarr"}
      </Button>
      <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
        Continuer
      </Button>
    </Box>
  );
};

export default AddMatrixStep1;