import { ICost, IMatriceUnit } from "../../interface/matrice";

interface newCost {
    C: number;
    RTUD: number;
    RTUI: number;
    QS: number;
    QC: number;
    QE: number;
    RS: number;
    RC: number;
    RE: number;
    D: number;
    PC: number;
    A: number;
    G: number;
    PPR: number;
    [key: string]: number;
  }

// Mettre la matrice bdd au format de la matrice dans redux
export function matrixFormat(matrice: Array<IMatriceUnit>) {
  let copyCosts: any = [];
  copyCosts = matrice.map((matriceUnit) => {
    // Permet de trouver tous les couts avec la mention isChoosen à true ou alors justement aucune mention
    return matriceUnit.costs.find(
      (item: ICost) => item.isChoosen || item.isChoosen === undefined
    );
  });

  let newCosts: newCost = {
    C: 0,
    RTUD: 0,
    RTUI: 0,
    QS: 0,
    QC: 0,
    QE: 0,
    RS: 0,
    RC: 0,
    RE: 0,
    D: 0,
    PC: 0,
    A: 0,
    G: 0,
    PPR: 0,
  };

  Object.keys(newCosts).forEach((key: string, index) => {
    newCosts[`${key}`] = copyCosts[index];
    if (copyCosts[index] === undefined) {
      newCosts[key] = 0;
    }
  });
  return newCosts;
}
