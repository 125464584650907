// React
import { useState, Fragment } from "react";

// Material UI
import { Typography, Divider, Menu, Button, Checkbox, FormControlLabel, Box } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Item from "@mui/material/ListItem";
import Fade from "@mui/material/Fade";

// Icons
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";

// Tools
import { extractCodeCategory } from "../../../tools/history";
import { handleFilterClick, handleFilterClose, handleFilterChange, handleSelectAll } from "../../../tools/history";

const categories = [
  "Réponses informatives",
  "Réponses de succès",
  "Redirections",
  "Erreurs des clients",
  "Erreurs des serveurs",
  "Autres",
];

const constructCodesCategories = (codes: string[]) => {
  const codesCategorized = new Map<string, string[]>();

  categories.forEach((category) => codesCategorized.set(category, []));

  codes.forEach((code) => {
    const category = extractCodeCategory(code.toString());
    const currentCodes = codesCategorized.get(category);

    if (currentCodes) {
      currentCodes.push(code);
      codesCategorized.set(category, currentCodes);
    }
  });

  return codesCategorized;
};

const CodeFilter = ({
  currentCodes,
  setCurrentCodes,
  codes,
}: {
  currentCodes: string[];
  setCurrentCodes: React.Dispatch<React.SetStateAction<string[]>>;
  codes: string[];
}) => {
  const [codesFilterAnchorEl, setCodesFilterAnchorEl] = useState<null | HTMLElement>(null);

  const codesFilterOpen = Boolean(codesFilterAnchorEl);

  const codesCategorized = constructCodesCategories(codes);

  return (
    <Fragment>
      <Menu
        id="person-filter"
        anchorEl={codesFilterAnchorEl}
        open={codesFilterOpen}
        onClose={handleFilterClose(setCodesFilterAnchorEl)}
        MenuListProps={{
          "aria-labelledby": "person-filter",
        }}
        TransitionComponent={Fade}
        PaperProps={{
          style: {
            maxHeight: 600,
            width: "27ch",
          },
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={currentCodes.length === codes.length}
              indeterminate={currentCodes.length > 0 && currentCodes.length < codes.length}
              onChange={handleSelectAll(setCurrentCodes, codes, currentCodes)}
              size="small"
            />
          }
          label={<span style={{ fontWeight: 500 }}>Select All</span>}
          sx={{
            pl: 2,
            pb: 0.5,
          }}
        />

        <Divider sx={{ mb: 0.5 }} />

        {Array.from(codesCategorized.entries()).flatMap(([category, codesCategory]) => {
          return (
            codesCategory.length !== 0 && (
              <Box key={category}>
                <Typography variant="subtitle2" sx={{ pl: 2, pt: 1, pb: 0.5, opacity: 0.5 }}>
                  {category}
                </Typography>
                {codesCategory.map((code) => (
                  <MenuItem key={code} dense sx={{ p: 0 }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={currentCodes.includes(code)}
                          onChange={handleFilterChange(setCurrentCodes, currentCodes)(code)}
                          size="small"
                        />
                      }
                      label={<span style={{ textTransform: "none" }}>{code}</span>}
                      sx={{
                        pl: 5,
                        py: 0.5,
                      }}
                    />
                  </MenuItem>
                ))}
              </Box>
            )
          );
        })}
        <Divider />
        <Button
          size="small"
          onClick={() => {
            setCurrentCodes([]);
          }}
          sx={{ ml: 2, mt: 1 }}
        >
          Clear ({currentCodes.length})
        </Button>
      </Menu>
      <Item sx={{ width: 120 }}>
        <Button
          id="person-filter"
          aria-controls="person-filter"
          aria-haspopup="true"
          aria-expanded={codesFilterOpen ? "true" : undefined}
          onClick={handleFilterClick(setCodesFilterAnchorEl)}
          endIcon={<ExpandMoreRoundedIcon />}
        >
          <Typography variant="subtitle2">Status</Typography>
        </Button>
      </Item>
    </Fragment>
  );
};

export default CodeFilter;