// React
import { useState, useEffect } from "react";

// Redux
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers/index";
import { useDispatch } from "react-redux";
import { sessionEnd, showSnackbar } from "../../redux/actions/index";

// MaterialUI
import { Card } from "@mui/material";
import Box from "@mui/material/Box";

// Interface
import { MatriceWrapperClientsData } from "../../interface/matrice";

// Fetch
import { getAll } from "../../fetchs/get";
import { deleteOneMatrix } from "../../fetchs/delete";
import { duplicateMatrix } from "../../fetchs/post";
import { fetchPostProtect } from "../../fetchs/post";

// Components
import MatrixBox from "../../components/ListMatrix/MatrixBox";
import MatrixDialogEdit from "../../components/ListMatrix/MatrixDialogEdit";
import SearchFilterBar from "../../components/ListMatrix/SearchFilterBar";
import AlerteDialog from "../../components/AlerteDialog/AlerteDialog";

const MatrixHome = () => {
  const [allMatrice, setAllMatrice] = useState<Array<MatriceWrapperClientsData>>([]);
  const [filteredMatrice, setFilteredMatrice] = useState<Array<MatriceWrapperClientsData>>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedClients, setSelectedClients] = useState<string[]>([]);
  const [openModal, setOpenModal] = useState<Boolean>(false);
  const [matrixModal, setMatrixModal] = useState<MatriceWrapperClientsData | undefined>(undefined);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState<boolean>(false);
  const [openForceDeleteConfirmation, setOpenForceDeleteConfirmation] = useState<boolean>(false);
  const [matriceToDelete, setMatriceToDelete] = useState<string>("");
  const [openDuplicateConfirmation, setOpenDuplicateConfirmation] = useState<boolean>(false);
  const [matriceToDuplicate, setMatriceToDuplicate] = useState<string[]>([]);

  let displayOnlyOwnMatrice = false;

  const dispatch = useDispatch();

  const token = useSelector((state: RootState) => {
    return state.user.token;
  });

  const author = useSelector((state: RootState) => {
    return state.user.username;
  });

  /**
   * Request pour récupérer toutes les matrices
   */
  const getAllMatrix = (force = false) => {
    if (token && (allMatrice.length === 0 || force)) {
      getAll(
        token,
        "/matrice/all",
        setAllMatrice,
        () => dispatch(sessionEnd()),
        (err: any) => {
          dispatch(showSnackbar("Erreur lors de la récupération des matrices : " + err?.error, "error"));
        }
      );
    }
  };

  useEffect(() => {
    if (allMatrice.length > 0) getAllMatrix();
    setFilteredMatrice(allMatrice);
  }, [allMatrice]);

  useEffect(() => {
    getAllMatrix();
  }, []);

  /**
   * Récupère les clients uniques de toutes les matrices pour les afficher dans l'autocomplete sans doublons
   * @param matrices liste des matrices
   * @returns liste des clients uniques
   */
  const getUniqueClients = (matrices: Array<MatriceWrapperClientsData>) => {
    const clientSet = new Set<{ name: string; _id: string }>();

    matrices.forEach((matrice) => {
      matrice.clients?.forEach((client) => {
        const clientExistsInSet = Array.from(clientSet).some((uniqueClient) => uniqueClient._id === client._id);
        if (!clientExistsInSet) clientSet.add(client);
      });
    });

    return Array.from(clientSet);
  };

  const uniqueClients = getUniqueClients(allMatrice);

  /**
   * Filtre les matrices en fonction du nom, des clients sélectionnés dans l'autocomplete et
   * de l'option d'affichage des matrices personnelles
   * @param nameSearchTerm input de recherche
   * @param clientNames clients sélectionnés dans l'autocomplete
   * @returns liste des matrices filtrées en fonction des paramètres
   */
  const filterMatrices = (nameSearchTerm: string, clientNames: string[]) => {
    return allMatrice.filter((matrice) => {
      const isOwnMatrice = displayOnlyOwnMatrice ? matrice.creator === author : true;
      const matchesName = matrice.name.toLowerCase().includes(nameSearchTerm.toLowerCase());
      const hasSelectedClient = matrice.clients?.some((client) => clientNames.includes(client.name));

      return matchesName && (clientNames.length === 0 || hasSelectedClient) && isOwnMatrice;
    });
  };

  /**
   * Gère le changement de l'input de recherche
   * @param e évènement de changement de l'input
   */
  const handleSearchTermChange = (value: string) => {
    setSearchTerm(value);
    setFilteredMatrice(filterMatrices(value, selectedClients));
  };

  /**
   * Gère le changement de l'autocomplete des clients
   * @param e évènement de changement de l'autocomplete
   * @param value valeur de l'autocomplete
   */
  const handleClientSelectionChange = (value: string[]) => {
    setSelectedClients(value);
    setFilteredMatrice(filterMatrices(searchTerm, value));
  };

  /**
   * Gère le clic sur un client dans la liste des clients dans une box de matrice et l'affiche dans l'autocomplete
   * des clients et filtre les matrices en fonction du client sélectionné
   * @param clientName nom du client sélectionné
   */
  const handleClientClick = (clientName: string) => {
    setSelectedClients([clientName]);
    setFilteredMatrice(filterMatrices(searchTerm, [clientName]));
  };

  /**
   * Gère l'ouverture de la modal d'édition de matrice
   * @param matrice matrice à éditer
   */
  const openEditModal = (matrice: MatriceWrapperClientsData) => {
    setMatrixModal(matrice);
    setOpenModal(true);
  };

  /**
   * Enregistre l'ID de la matrice à supprimer et ouvre la modal de confirmation de suppression
   * @param matriceID id de la matrice à supprimer
   */
  const handleDeleteConfirmation = (matriceID: string) => {
    setMatriceToDelete(matriceID);
    setOpenDeleteConfirmation(true);
  };

  /**
   * Enregistre l'ID de la matrice à dupliquer et ouvre la modal de confirmation de duplication
   * @param matriceID id de la matrice à dupliquer
   */
  const handleDuplicateConfirmation = (matriceID: string, matriceName: string) => {
    setMatriceToDuplicate([matriceID, matriceName]);
    setOpenDuplicateConfirmation(true);
  };

  /**
   * Gère le changement du choix d'affichage des matrices personnelles
   * @param event évènement de changement de l'input de l'option d'affichage des matrices personnelles
   */
  const handlePersonalMatriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    displayOnlyOwnMatrice = event.target.checked;
    setFilteredMatrice(filterMatrices(searchTerm, selectedClients));
  };

  /**
   * Envoie la matrice modifiée au back pour la mettre à jour dans la base de données
   * Ouverture d'une snackbar en fonction du résultat de la requête
   * @param updatedMatriceData matrice modifiée
   */
  const pushModifiedMatrice = (updatedMatriceData: MatriceWrapperClientsData) => {
    const body: MatriceWrapperClientsData = {
      ...updatedMatriceData,
      name: updatedMatriceData.name.trim(),
      desc: updatedMatriceData.desc ? updatedMatriceData.desc.trim() : "",
    };
    // body.clients = body.clients?.map((client) => client._id) || [];
    if (token) {
      fetchPostProtect(token, "/matrice/update", JSON.stringify(body)).then((res) => {
        if (res.status === 200) {
          dispatch(showSnackbar("Matrice modifiée avec succès", "success"));
        } else {
          res.json().then((err) => {
            dispatch(showSnackbar("Erreur lors de la modification de la matrice : " + err.error, "error"));
          });
        }
      });
    }
  };

  /**
   * Request pour supprimer une matrice dans la base de données
   * Ouverture d'une snackbar en fonction du résultat de la requête
   * @param force true si la suppression est forcée, false sinon
   */
  const handleDeleteMatrice = (force: boolean) => {
    if (token) {
      let url = force ? "/matrice/force" : "/matrice";
      deleteOneMatrix(token, url, matriceToDelete).then((res) => {
        if (res.status === 200) {
          dispatch(showSnackbar("Matrice supprimée avec succès", "success"));
          force ? setOpenForceDeleteConfirmation(false) : setOpenDeleteConfirmation(false);

          const newAllMatrice = allMatrice.filter((matrice) => matrice._id !== matriceToDelete);
          setAllMatrice(newAllMatrice);
        } else if (!force && res.status === 409) {
          setOpenDeleteConfirmation(false);
          setOpenForceDeleteConfirmation(true);
        } else {
          res.json().then((err) => {
            dispatch(showSnackbar("Erreur lors de la suppression de la matrice : " + err.error, "error"));
            force ? setOpenForceDeleteConfirmation(false) : setOpenDeleteConfirmation(false);
          });
        }
      });
    }
  };

/**
 * Request pour dupliquer une matrice dans la base de données
 */
const handleDuplicateMatrice = () => {
  if (token) {
    const url = "/matrice/duplicate";
    duplicateMatrix(token, url, matriceToDuplicate).then((res) => {
      if (res.status === 200) {
        dispatch(showSnackbar("Matrice dupliquée avec succès", "success"));
        setOpenDuplicateConfirmation(false);
        getAllMatrix(true);
      } else if (res.status === 409) {
        setOpenDuplicateConfirmation(false);
      } else {
        res.json().then((err) => {
          dispatch(showSnackbar("Erreur lors de la duplication de la matrice : " + err.error, "error"));
          setOpenDuplicateConfirmation(false);
        });
      }
    });
  }
};

  /**
   * Met à jour une matrice dans la liste des matrices
   * @param matrice matrice à mettre à jour
   */
  const updateOneMatrix = (matrice: MatriceWrapperClientsData) => {
    const newAllMatrice = allMatrice.map((matriceInAllMatrice) => {
      if (matriceInAllMatrice._id === matrice._id)
        return {
          ...matriceInAllMatrice,
          name: matrice.name,
          desc: matrice.desc,
          clients: matrice.clients,
        };
      else return matriceInAllMatrice;
    });
    setAllMatrice(newAllMatrice);
  };

  return (
    <Card
      elevation={0}
      component="main"
      sx={{
        backgroundColor: "white",
        m: 2,
        p: 2,
        borderRadius: "10px",
        height: "calc(100vh - 100px)",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {openModal && matrixModal && (
        <MatrixDialogEdit
          matrice={matrixModal}
          setOpenModal={setOpenModal}
          pushModifiedMatrice={pushModifiedMatrice}
          updateOneMatrix={updateOneMatrix}
        />
      )}

      {openDuplicateConfirmation && (
        <AlerteDialog
          open={openDuplicateConfirmation}
          handleClose={() => setOpenDuplicateConfirmation(false)}
          deleteProject={() => handleDuplicateMatrice()}
          title={"Dupliquer la matrice."}
          contentText={"Souhaitez vous dupliquer cette matrice ?"}
        />
      )}

      {openDeleteConfirmation && (
        <AlerteDialog
          open={openDeleteConfirmation}
          handleClose={() => setOpenDeleteConfirmation(false)}
          deleteProject={() => handleDeleteMatrice(false)}
          title={"Souhaitez vous vraiment supprimer cette matrice ?"}
          contentText={"Êtes-vous sûr de vouloir supprimer cette matrice ? Cette action est irréversible."}
        />
      )}

      {openForceDeleteConfirmation && (
        <AlerteDialog
          open={openForceDeleteConfirmation}
          handleClose={() => setOpenForceDeleteConfirmation(false)}
          deleteProject={() => handleDeleteMatrice(true)}
          title={"Attention, cette matrice est utilisée par d'autres matrices !"}
          contentText={
            "Cette matrice est utilisée par d'autres matrices. Si vous la supprimez, les matrices associées ne pourront plus fonctionner correctement. Êtes-vous sûr de vouloir supprimer cette matrice ? Cette action est irréversible."
          }
          color="error"
        />
      )}

      <Box sx={{ display: "flex", justifyContent: "start", flexDirection: "column", height: "100%" }}>
        <SearchFilterBar
          handleSearchTermChange={handleSearchTermChange}
          handleClientSelectionChange={handleClientSelectionChange}
          selectedClients={selectedClients}
          uniqueClients={uniqueClients}
          handlePersonalMatriceChange={handlePersonalMatriceChange}
          updateOneMatrix={updateOneMatrix}
          getAllMatrix={getAllMatrix}
        ></SearchFilterBar>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "row",
            alignItems: "center",
            flexWrap: "wrap",
            overflowY: "auto",
          }}
        >
          {filteredMatrice.map((matrice: MatriceWrapperClientsData) => {
            return (
              matrice.type === "template" && (
                <MatrixBox
                  key={matrice._id}
                  matrice={matrice}
                  handleClientClick={handleClientClick}
                  setOpenModal={openEditModal}
                  handleDeleteConfirmation={handleDeleteConfirmation}
                  handleDuplicateConfirmation={() => handleDuplicateConfirmation(matrice._id, matrice.name)}
                ></MatrixBox>
              )
            );
          })}
        </Box>
      </Box>
    </Card>
  );
};

export default MatrixHome;