import React from "react";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createRoot } from 'react-dom/client';

//AzureAD
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./config/authConfig";
import { MsalProvider } from "@azure/msal-react";

// Redux
import { createStore } from "redux";
import rootReducer from "./redux/reducers";
import { Provider } from "react-redux";
import { persistStore, persistReducer } from 'redux-persist'
import { PersistGate} from 'redux-persist/integration/react';
import storage from 'redux-persist/lib/storage'

// Permet d'avoir access au store en installer la dependance redux devtools-extension + cette commande.
import { composeWithDevTools } from "redux-devtools-extension";

// MaterialUI Theme
import { theme } from "./config/theme";
import { ThemeProvider } from "@mui/material/styles";

// Doit absolutement être déclaré ici pour AzureAD
export const msalInstance = new PublicClientApplication(msalConfig);

const persistConfig = {
  key: 'root',
  storage,
}
 
const persistedReducer = persistReducer(persistConfig, rootReducer)

  let store = createStore(persistedReducer, composeWithDevTools())
  let persistor = persistStore(store)

createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <Provider store={store}>
            <MsalProvider instance={msalInstance}>
                <ThemeProvider theme={theme}>
                    <PersistGate loading={null} persistor={persistor}>
                        <App />
                    </PersistGate>
                </ThemeProvider>
            </MsalProvider>
        </Provider>
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
