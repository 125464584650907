// Fetch
import { getAll, fetchGetProtect } from "../fetchs/get";

// Redux
import { sessionEnd } from "../redux/actions/index";

/**
 * Fonction qui permet de mettre à jour les clients Dolibarr en faisant un appel à l'API dolibarr
 * @param token token de l'utilisateur
 * @param setError fonction qui permet de mettre à jour le state error
 * @param setMessage fonction qui permet de mettre à jour le state message
 * @param setClients fonction qui permet de mettre à jour le state clients
 * @param dispatch fonction redux qui permet de mettre à jour le state session
 */
export function updateDolibarrClients(
  token: string | undefined,
  setError: (error: string) => void,
  setMessage: (message: string) => void,
  setClients: (clients: Array<{ name: string; _id: string }>) => void,
  dispatch: (arg0: { type: string }) => void
) {
  setError("");
  setMessage("");
  if (token) {
    fetchGetProtect(token, "/clients/dolibarr/").then((response) => {
      if (response.status === 500) {
        response.json().then((jsonResponse) => {
          setError(jsonResponse.message);
        });
      } else if (response.status === 200) {
        response.json().then((jsonResponse) => {
          setMessage(jsonResponse.message);
          if (token) {
            getAll(
              token,
              "/clients/",
              setClients,
              () => {
                dispatch(sessionEnd());
              },
              setError
            );
          }
        });
      }
    });
  }
}
