import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/reducers/index";
import { sessionEnd, showSnackbar } from "../../redux/actions";

// Websocket
// import { io } from "socket.io-client";
// import { wsURL } from "../../config/config";

// MaterialUI et Layout
import {
  DataGrid,
  GridRowsProp,
  GridColDef,
  GridSortModel,
  getGridStringOperators,
  getGridDateOperators,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  gridFilteredSortedRowIdsSelector,
  useGridApiContext,
} from "@mui/x-data-grid";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions
} from "@mui/material";
import { Card, Alert, Button, Pagination } from "@mui/material";
import ContentCopyTwoToneIcon from "@mui/icons-material/ContentCopyTwoTone";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import LockTwoToneIcon from '@mui/icons-material/LockTwoTone';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import IconButton from "@mui/material/IconButton";

// Fetchs
import { getAll, getWithFilter } from "../../fetchs/get";
import { deleteOne } from "../../fetchs/delete";
import { fetchPostProtect } from "../../fetchs/post";

import { IProject } from "../../interface/project";
import { useNavigate } from "react-router-dom";
import AlerteDialog from "../../components/AlerteDialog/AlerteDialog";
import CustomNoRowsOverlay from "../../components/CustomNoRowsOverlay";

import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "19ch",
      "&:focus": {
        width: "22ch",
      },
    },
  },
}));

const ListProjects = () => {
  const [projects, setProjects] = useState<IProject[]>([]);
  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);
  const [openConfirmCopy, setOpenConfirmCopy] = useState(false);
  const [projectId, setProjectId] = useState();
  const [lockedProjetsList, setLockedProjetsList] = useState<{ projectId: string; isLock: boolean }[]>([]);
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "createdAt",
      sort: "desc",
    },
  ]);
  const [openSpam, setOpenSpam] = useState(false);

  let dispatch = useDispatch();
  let navigate = useNavigate();

  // Websocket

  // useEffect(() => {
  //   console.log("ici ici ici", wsURL)
  //   const socket = io(wsURL, { transports: ['websocket', 'polling'] });


  //   socket.on("areProjectsLocked2", (projectsLockedList) => {
  //     setLockedProjetsList(projectsLockedList);
  //   });

  //   socket.emit("areProjectsLocked2");

  //   return () => {
  //     socket.disconnect();
  //   };
  // }, []);

  let token = useSelector((state: RootState) => {
    return state.user.token;
  });

  const getAllProjects = () => {
    if (token) {
      getAll(
        token,
        "/projects/",
        setProjects,
        () => {
          dispatch(sessionEnd());
        },
        setError
      );
    }
  };

  useEffect(() => {
    getAllProjects();
  }, []);

  const handleClickOpen = (id: any) => {
    setProjectId(id);
    setOpen(true);
  };

  const handleClickOpenDuplicate = (id: any) => {
    setProjectId(id);
    setOpenConfirmCopy(true);
    return id;
  };

  const handleClose = () => {
    setOpen(false);
    setOpenConfirmCopy(false);
  };

  const handleCloseSpam = (event: React.MouseEvent | React.KeyboardEvent, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return;
    }
  }

  const duplicateProject = () => {
    if (token && projectId) {
      const project = projects.find((p: IProject) => p._id === projectId);
      if (project) {
        const requestBody = JSON.stringify({
          name: project.name,
          _id: project._id
        })
        fetchPostProtect(token, "/projects/duplicate", requestBody)
          .then((res) => {
            setOpenConfirmCopy(false);
            dispatch(showSnackbar("Projet dupliqué avec succès !", "success"));
            if (res.status === 200 && token) {
              getAll(token, "/projects/", setProjects, () => dispatch(sessionEnd()), setError);
            } else if (res.status === 401) {
              dispatch(sessionEnd());
            } else if (res.status === 404 && token) {
              getAll(token, "/projects/", setProjects, () => dispatch(sessionEnd()), setError);
              res.json().then((err) => {
                setError(err.error);
                dispatch(showSnackbar("Erreur lors de la duplication du projet : " + err.error, "error"));
              });
            }
          })
          .catch((err) => setError(err));
      } else {
        dispatch(showSnackbar("Erreur lors de la duplication du projet : Id du projet introuvable", "error"));
      }
    }
  };

  const deleteProject = () => {
    let id = projectId;
    if (token) {
      const lockedProject = lockedProjetsList.find((lockedProj) => lockedProj.projectId === id);
      if (lockedProject && lockedProject.isLock) {
        dispatch(showSnackbar("Impossible de supprimer un projet verrouillé.", "error"));
        return;
      }
      deleteOne(token, "/projects/", id).then((res) => {
        if (res.status === 200) {
          dispatch(showSnackbar("Projet supprimé avec succès !", "success"));
          getAllProjects();
        } else if (res.status === 401) {
          dispatch(sessionEnd());
        } else {
          res.json().then((err) => {
            setError(err.error);
            dispatch(showSnackbar("Erreur lors de la suppression du projet : " + err.error, "error"));
          });
        }
      });
    }
    handleClose();
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Nom",
      flex: 2,
      filterable: true,
      filterOperators: getGridStringOperators().filter((operator) => operator.value === "contains"),
    },
    {
      field: "client",
      headerName: "Client",
      flex: 2,
      filterable: true,
      filterOperators: getGridStringOperators().filter((operator) => operator.value === "contains"),
    },
    {
      field: "author",
      headerName: "Auteur",
      flex: 2,
      filterable: true,
      filterOperators: getGridStringOperators().filter((operator) => operator.value === "contains"),
    },
    {
      field: "lots",
      headerName: "Lots",
      flex: 1,
      filterable: true,
      filterOperators: getGridStringOperators().filter((operator) => operator.value === "contains"),
    },
    {
      field: "createdAt",
      headerName: "Crée le",
      flex: 1,
      type: "date",
      valueGetter: (params) => new Date(params.row.createdAt.split("/").reverse().join("-")),
      filterable: true,
      filterOperators: getGridDateOperators().filter(
        (operator) => operator.value === "is" || operator.value === "onOrAfter" || operator.value === "onOrBefore"
      ),
    },
    {
      field: "updatedAt",
      headerName: "Mis à jour le",
      flex: 1,
      type: "date",
      valueGetter: (params) => new Date(params.row.updatedAt.split("/").reverse().join("-")),
      filterable: true,
      filterOperators: getGridDateOperators().filter(
        (operator) => operator.value === "is" || operator.value === "onOrAfter" || operator.value === "onOrBefore"
      ),
    },
    {
      field: "copy",
      headerName: "Dupliquer",
      cellClassName: "center-cell",
      renderCell: () => {
        return (
          <IconButton>
            <ContentCopyTwoToneIcon sx={{ color: "orange" }} />
          </IconButton>
        );
      },
      filterable: false,
    },
    {
      field: "delete",
      headerName: "Supprimer",
      cellClassName: "center-cell",
      renderCell: () => {
        return (
          <IconButton>
            <DeleteTwoToneIcon sx={{ color: "red" }} />
          </IconButton>
        );
      },
      filterable: false,
    },
    {
      field: "editingStatus",
      headerName: "En utilisation",
      cellClassName: (params) => {
        return `${params.row.isLock ? "locked-row" : ""} center-cell`;
      },
      renderCell: (params) => {
        return (
          <IconButton disabled={params.row.isLock} onClick={() => handleProjectClick(params.row.id)}>
            {params.row.isLock ? (
              <LockTwoToneIcon sx={{ color: 'red' }} />) : (<LockOpenIcon sx={{ color: 'black' }} />
            )}
          </IconButton>
        );
      },
      filterable: false,
    },
  ];

  const rows: GridRowsProp = projects.map((project: IProject) => {
    const lockedProject = lockedProjetsList.find((lockedProj) => lockedProj.projectId === project._id);
    return {
      id: project._id,
      name: project.name,
      client: project.client ? project.client.name : "",
      author: project.author,
      lots: project.lots.length,
      createdAt: project.createdAt,
      updatedAt: project.updatedAt,
      isLock: lockedProject ? lockedProject.isLock : false,
    };
  });

  const handleProjectClick = (projectId: string) => {
    const lockedProject = lockedProjetsList.find((lockedProj) => lockedProj.projectId === projectId);
    if (lockedProject && lockedProject.isLock) {
      return;
    }
    navigate(`/projet/${projectId}`);
  };

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 8,
    page: 0,
  });

  const loadMoreProjects = () => {
    setPaginationModel((prevPaginationModel) => ({
      ...prevPaginationModel,
      pageSize: prevPaginationModel.pageSize + projects.length >= 99 ? 99 : prevPaginationModel.pageSize + projects.length
    }));
  };

  function CustomToolbar() {
    const rowsCount = rows.length;
  
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton sx={{ mx: 1 }} />
        <GridToolbarFilterButton sx={{ mx: 1 }} />
        
        {rowsCount <= 100 && (
          <GridToolbarExport
            sx={{ mx: 1 }}
            csvOptions={{ delimiter: ';', utf8WithBom: true, fileName: "Liste des projets" }}
            printOptions={{ fileName: "Liste des projets", hideToolbar: true }}
          />
        )}
        
        {rowsCount > 100 && (
          <GridToolbarExport
            sx={{ mx: 1 }}
            csvOptions={{ delimiter: ';', utf8WithBom: true, fileName: "Liste des projets" }}
            printOptions={{ disableToolbarButton: true }}
          />
        )}
        
      </GridToolbarContainer>
    );
  }

  return (
    <div className="listProjects">
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{
                flexGrow: 1,
                display: { xs: "none", sm: "block" },
              }}
            >
              Liste des projets
            </Typography>
          </Toolbar>
        </AppBar>
      </Box>
      <Card
        elevation={0}
        component="main"
        sx={{
          backgroundColor: "white",
          m: 2,
          p: 2,
          borderRadius: "10px",
          height: "calc(100vh - 164px)",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Button variant="contained" color="primary" sx={{ display: 'flex', justifyContent: 'flex-start', color: 'primary' }} onClick={loadMoreProjects} disabled={paginationModel.pageSize >= projects.length}>
            Voir tout les projets
          </Button>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Recherche d'un projet"
                inputProps={{ "aria-label": "search" }}
                onChange={(e) => {
                  if (token) {
                    getWithFilter(
                      token,
                      "/projects/filter/",
                      e.target.value,
                      setProjects,
                      () => {
                        dispatch(sessionEnd());
                      },
                      setError
                    );
                  }
                }}
              />
            </Search>
          </Box>
        </Box>
        {error ? <Alert severity="error">{error}</Alert> : null}

        <DataGrid
          disableRowSelectionOnClick={true}
          rows={rows}
          columns={columns}
          paginationModel={paginationModel}
          pageSizeOptions={[8]}
          onPaginationModelChange={setPaginationModel}
          sortModel={sortModel}
          onSortModelChange={(model) => setSortModel(model)}
          components={{
            Toolbar: CustomToolbar,
            NoRowsOverlay: CustomNoRowsOverlay,
          }}
          sx={{
            "& .MuiDataGrid-row": {
              cursor: "pointer",
            },
            height: "100%",
          }}
          onCellClick={(row) => {
            if (row.field === "delete") {
              handleClickOpen(row.id);
            } else if (row.field === "copy") {
              handleClickOpenDuplicate(row.id);
            } else {
              const lockedProject = lockedProjetsList.find((proj) => proj.projectId === row.id);
              if (lockedProject && lockedProject.isLock) {
              } else {
                navigate(`/projet/${row.id}`);
              }
            }
          }}
        />
      </Card>
      {open && (
        <AlerteDialog
          open={open}
          handleClose={handleClose}
          deleteProject={deleteProject}
          title={"Souhaitez vous vraiment supprimer ce projet ?"}
          contentText={
            "Attention vous êtes sur le point de supprimer définitivement ce projet. Cette action est irréversible et supprimera tous les lots et matrices associés à ce projet."
          }
        />
      )}
      {openConfirmCopy && (
        <AlerteDialog
          open={openConfirmCopy}
          handleClose={handleClose}
          deleteProject={duplicateProject}
          title={"Dupliquer le projet."}
          contentText={"Souhaitez vous dupliquer ce projet ?"}
          
        />
      )}
      {openSpam && (
        <Dialog
          open={openSpam}
          keepMounted={false}
          onClose={handleCloseSpam}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          disableEscapeKeyDown
        >
          <DialogTitle>ALERTE AU SPAM !</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">Veuillez ne pas spammer de click ce bouton au risque de faire planter la page !</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => window.location.reload()} variant="contained" color="error">
              OK
            </Button>
        </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

export default ListProjects;