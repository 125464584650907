// React
import { useEffect, useState } from "react";

// Material UI
import { Dialog, IconButton, DialogContent, DialogTitle, Button, Alert, Box, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";
import DialogActions from "@mui/material/DialogActions";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/reducers/index";
import { sessionEnd, showSnackbar } from "../../redux/actions/index";

// Interface
import { MatriceWrapperClientsData, ICost, ICostChanges, MatriceMergeData } from "../../interface/matrice";

// Fetch
import { getOne } from "../../fetchs/get";
import { fetchPostProtect } from "../../fetchs/post";

// Icons
import CloseIcon from "@mui/icons-material/Close";

// Components
import SyncTable from "./SyncTable";

const flexCenter: SxProps<Theme> = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
};

/**
 * Calcule si un merge est nécessaire et si il y a conflit
 * @return 0 = pas de merge, 1 = merge possible, 2 = merge avec conflit, 3 = mergé
 */
const mergeCalc = (
  parentCell: ICost,
  currentCell: ICost,
  parentChanges: ICostChanges,
  currentChanges: ICostChanges,
  type: "rate" | "cost"
): 0 | 1 | 2 => {
  if (parentCell[type] !== currentCell[type]) {
    if (parentChanges[type].length === 0 || currentChanges[type].length === 0) {
      return 1;
    } else {
      return 2;
    }
  } else {
    return 0;
  }
};

const mergeChooseCalc = (
  parentCell: ICost,
  currentCell: ICost
) : 0 | 1 => {
  if(parentCell["isChoosen"] !== currentCell["isChoosen"]){
    return 1;
  }
  else{
    return 0;
  }
}

const SyncDialog = ({
  setOpenModal,
  matrice,
  setMatrice,
}: {
  setOpenModal: Function;
  matrice: MatriceWrapperClientsData;
  setMatrice: Function;
}) => {
  const [parentMatrix, setParentMatrix] = useState<MatriceWrapperClientsData | null>(null);
  const [newMatrix, setNewMatrix] = useState<MatriceWrapperClientsData | null>(null);
  const [mergeObject, setMergeObject] = useState<MatriceMergeData[] | null>(null);

  const [totalPossibleMerge, setTotalPossibleMerge] = useState<number>(0);
  const [totalMerge, setTotalMerge] = useState<number>(0);

  let dispatch = useDispatch();
  let token = useSelector((state: RootState) => {
    return state.user.token;
  });

  useEffect(() => {
    getParentMatrix();
  }, [matrice]);

  useEffect(() => {
    getMatrix(matrice._id, setNewMatrix);
  }, []);

  useEffect(() => {
    if (!mergeObject && parentMatrix?.changes.length !== 0 && newMatrix) computeMergeObject();
  }, [parentMatrix, newMatrix]);

  /**
   * Récupère la matrice parente de la matrice actuelle
   * Si la matrice actuelle est un template (pas de valeur dans basedOn), la matrice parente est la matrice project_master
   * Sinon, la matrice parente est la matrice dont l'id est dans basedOn
   */
  const getParentMatrix = () => {
    //basedOn == matrice précédente
    if (matrice.basedOn) {
      getMatrix(matrice.basedOn, setParentMatrix, "/matrice/id/");
    } else if (matrice.basedOn === null) {
      getMatrix("project_master", setParentMatrix, "/matrice/master/");
    }
  };

  /**
   * Récupère une matrice
   * @param matrixID ID de la matrice à récupérer
   * @param setMatrix Fonction pour mettre à jour la matrice
   * @param route Route pour récupérer la matrice
   */
  const getMatrix = (matrixID: string, setMatrix: Function, route: string = "/matrice/id/") => {
    if (token) {
      getOne(token, route, matrixID, setMatrix,
        () => {dispatch(sessionEnd())}, 
        (err: any) => { 
          dispatch(showSnackbar("Erreur : " + err?.error, "error"));
         });
    }
  };

  // Envoi des changements de la matrice au serveur
  const sendNewMatrix = () => {
    if (token) {
      let newMatrixData = { ...newMatrix, name: newMatrix?.name.trim(), desc: newMatrix?.desc ? newMatrix?.desc.trim() : "" };

      fetchPostProtect(token, "/matrice/update", JSON.stringify(newMatrixData)).then((res) => {
        if (res.status === 200) {
          setMatrice(newMatrixData);
          dispatch(showSnackbar("Matrice mise à jour", "success"));
        } else if (res.status === 401) {
          dispatch(sessionEnd());
        } else {
          res.json().then((err) => {
            dispatch(showSnackbar("Erreur lors de la mise à jour : " + err.error, "error"));
          });
        }
        setOpenModal(false);
      });
    }
  };

  /**
   * Calcule l'object de merge utilisé pour connaitre l'état d'un merge d'une cellule et le nombre de merge possible
   * 0 = pas de merge, 1 = merge possible, 2 = merge avec conflit, 3 = mergé
   */
  const computeMergeObject = () => {
    if (parentMatrix && newMatrix) {
      setTotalPossibleMerge(0);
      setTotalMerge(0);

      const mergeObject = newMatrix.data.map((row, indexRow) => {
        let rowTotalMerges = 0;
        return {
          costs: row.costs.map((cell, indexCell) => {
            let rateMerge = mergeCalc(
              parentMatrix.data[indexRow].costs[indexCell],
              cell,
              parentMatrix.changes[indexRow].costs[indexCell],
              newMatrix.changes[indexRow].costs[indexCell],
              "rate"
            );
            let costMerge = mergeCalc(
              parentMatrix.data[indexRow].costs[indexCell],
              cell,
              parentMatrix.changes[indexRow].costs[indexCell],
              newMatrix.changes[indexRow].costs[indexCell],
              "cost"
            );
            let choosedMerge = mergeChooseCalc(
              parentMatrix.data[indexRow].costs[indexCell],
              cell
            );

            rowTotalMerges += rateMerge + costMerge + choosedMerge;
            setTotalPossibleMerge((prev) => prev + (rateMerge % 2) + (costMerge % 2) + (choosedMerge % 2));
            setTotalMerge((prev) => prev + rowTotalMerges);

            return {
              rate: rateMerge,
              cost: costMerge,
              isChoosen : choosedMerge,
            };
          }),
          display: rowTotalMerges > 0,
        };
      });
      setMergeObject(mergeObject);
    }
  };

  /**
   * Effectue tout les merge possible de la matrice parente dans la matrice actuelle (quand la cellule = 1 dans mergeObject)
   * Compte le nombre de merge effectués et affiche un snackbar
   */
  const mergePossible = () => {
    let numberOfChanges = 0;
    if (parentMatrix?.changes) {
      parentMatrix.changes.forEach((parentRow: any, indexRow: number) => {
        for (let indexCell = 0; indexCell < parentRow.costs.length; indexCell++) {
          if (mergeObject && mergeObject[indexRow].costs[indexCell].rate === 1) {
            merge(indexRow, indexCell, "rate");
            numberOfChanges++;
          }
          if (mergeObject && mergeObject[indexRow].costs[indexCell].cost === 1) {
            merge(indexRow, indexCell, "cost");
            numberOfChanges++;
          }
          if (mergeObject && mergeObject[indexRow].costs[indexCell].isChoosen === 1) {
            choosedMerge(indexRow, indexCell);
            numberOfChanges++;
          }
        }
      });
    }

    dispatch(showSnackbar(`${numberOfChanges} merge ont été effectués`, "success"));
  };

  const mergeCheckbox = () => {
    let numberOfChanges = 0;
    if (parentMatrix?.changes) {
      parentMatrix.changes.forEach((parentRow: any, indexRow: number) => {
        for (let indexCell = 0; indexCell < parentRow.costs.length; indexCell++) {
          if (mergeObject && mergeObject[indexRow].costs[indexCell].isChoosen === 1) {
            choosedMerge(indexRow, indexCell);
            numberOfChanges++;
          }
        }
      });
    }

    dispatch(showSnackbar(`Merges de niveaux effectués avec succès`, "success"));
  };

  /**
   * Controleur de merge qui permet de merge une cellule de la matrice parente dans la matrice actuelle
   * @param indexRow indice de la ligne de la cellule à merge
   * @param indexCell indice de la cellule à merge
   * @param type type de la cellule à merge (rate ou cost)
   * @param conflictStatut statut du conflit (0 = pas de conflit, 1 = conflit parent -> actuel, 2 = conflit parent <- actuel)
   */
  const merge = (indexRow: number, indexCell: number, type: "rate" | "cost", conflictStatut: 0 | 1 | 2 = 0) => {
    if (!newMatrix) return;

    let parentData = parentMatrix?.data[indexRow].costs[indexCell][type];
    let currentChanges = newMatrix.changes[indexRow].costs[indexCell][type];
    let parentChanges = parentMatrix?.changes[indexRow].costs[indexCell][type];

    if (currentChanges && parentChanges) {
      let newChanges = [...newMatrix.changes];
      newChanges[indexRow].costs[indexCell][type] = [];

      if (conflictStatut === 0 || conflictStatut === 1) {
        let newMatrixData = [...newMatrix.data];
        newMatrixData[indexRow].costs[indexCell][type] = parentData;

        if (conflictStatut === 0) setTotalPossibleMerge((prev) => prev - 1);
        setNewMatrix({ ...newMatrix, changes: newChanges, data: newMatrixData });
      } else {
        setNewMatrix({ ...newMatrix, changes: newChanges });
      }

      setMergeObject((prev: MatriceMergeData[] | null) => {
        if (!prev) return prev;
        prev[indexRow].costs[indexCell][type] = 3;
        return prev;
      });
    }
  };

  const choosedMerge = (indexRow: number, indexCell: number, conflictStatut: 0 | 1 | 2 = 0) => {
    if (!newMatrix) return;

    let parentData = parentMatrix?.data[indexRow].costs[indexCell]["isChoosen"];
    let newChanges = [...newMatrix.changes];

    if (conflictStatut === 0 || conflictStatut === 1) {
      let newMatrixData = [...newMatrix.data];
      newMatrixData[indexRow].costs[indexCell]["isChoosen"] = parentData;

      if (conflictStatut === 0) setTotalPossibleMerge((prev) => prev - 1);
      setNewMatrix({ ...newMatrix, changes: newChanges, data: newMatrixData });
    } else {
      setNewMatrix({ ...newMatrix, changes: newChanges });
    }

    setMergeObject((prev: MatriceMergeData[] | null) => {
      if (!prev) return prev;
      prev[indexRow].costs[indexCell]["isChoosen"] = 3;
      return prev;
    });
  };

  /**
   * Reset les valeurs de la matrice à celles de la matrice originale
   * @param all indique si on reset toute la matrice ou seulement une cellule
   * @param indexRow indice de la ligne à reset (si all = false)
   * @param indexCell indice de la cellule à reset (si all = false)
   * @param type type de la cellule à reset (si all = false) (rate ou cost)
   */
  const resetMerge = (all: boolean, indexRow?: number, indexCell?: number, type?: "rate" | "cost") => {
    if (all) {
      setMergeObject(null);
      setNewMatrix(null);
      getParentMatrix();
      getMatrix(matrice._id, setNewMatrix);
    } else if (indexRow !== undefined && indexCell !== undefined && type !== undefined) {
      setNewMatrix((prev) => {
        if (prev === null) return prev;
        prev.data[indexRow].costs[indexCell][type] = matrice.data[indexRow].costs[indexCell][type];
        prev.changes[indexRow].costs[indexCell][type] = matrice.changes[indexRow].costs[indexCell][type];
        return prev;
      });

      setMergeObject((prev) => {
        if (prev === null || !parentMatrix) return prev;
        let updatedPrev = [...prev];
        updatedPrev[indexRow] = { ...updatedPrev[indexRow] };
        let mergeCellCalc = mergeCalc(
          parentMatrix.data[indexRow].costs[indexCell],
          matrice.data[indexRow].costs[indexCell],
          parentMatrix.changes[indexRow].costs[indexCell],
          matrice.changes[indexRow].costs[indexCell],
          type
        );
        if (mergeCellCalc === 1) setTotalPossibleMerge((prev) => prev + 1);
        updatedPrev[indexRow].costs[indexCell][type] = mergeCellCalc;
        return updatedPrev;
      });
    }
  };

  /** Affiche les titres de la table de synchronisation */
  function TableTitles() {
    return (
      <Grid container sx={{ mt: 2, borderRadius: 5, opacity: 0.5 }}>
        <Grid item xs={2} sx={flexCenter}>
          <Typography variant="subtitle2">Client</Typography>
        </Grid>
        <Grid item xs={10}>
          <Grid container>
            <Grid item xs={2} sx={flexCenter}>
              <Typography variant="subtitle2">Niveau</Typography>
            </Grid>
            <Grid item xs={10}>
              <Grid container columns={22}>
                <Grid item xs={3} sx={flexCenter}>
                  <Typography variant="subtitle2" align="center">
                    Catégorie
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle2" align="center">
                    Matrice actuelle
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="subtitle2" align="center">
                    Niveau actuel
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle2" align="center">
                    Action
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle2" align="center">
                    Matrice parente
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="subtitle2" align="center">
                    Niveau parent
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  return (
    <Dialog
      open={true}
      keepMounted={false}
      onClose={() => setOpenModal(false)}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      fullWidth={true}
      maxWidth="xl"
      scroll="paper"
      PaperProps={{
        sx: {
          height: "100%",
        },
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0 10px",
        }}
        id="scroll-dialog-title"
      >
        <DialogTitle>Synchronisation de la matrice</DialogTitle>
        <IconButton
          onClick={() => {
            setOpenModal(false);
          }}
        >
          <CloseIcon />
        </IconButton>
      </div>

      <DialogContent dividers={true} id="scroll-dialog-description">
        <Alert severity="info">
          <strong>Attention</strong> : La synchronisation de la matrice va écraser les données de la matrice actuelle.
        </Alert>
        <Stack direction="row" sx={{ mt: 1 }}>
          <Button variant="outlined" sx={{ m: 1 }} onClick={() => mergePossible()} color="success">
            Merger possible ({totalPossibleMerge})
          </Button>
          <Button variant="outlined" sx={{ m: 1 }} onClick={() => mergeCheckbox()} color="info">
            Merger niveaux
          </Button>
          <Button variant="outlined" sx={{ m: 1 }} onClick={() => resetMerge(true)} color="error">
            Reset
          </Button>
        </Stack>
        {parentMatrix && mergeObject && newMatrix ? (
          totalMerge ? (
            <>
              <TableTitles />
              <SyncTable
                newMatrix={newMatrix}
                parentMatrix={parentMatrix}
                merge={merge}
                mergeObject={mergeObject}
                resetMerge={resetMerge}
              />
            </>
          ) : (
            <Typography variant="subtitle2" align="center">
              Aucun merge possible
            </Typography>
          )
        ) : parentMatrix?.changes.length === 0 ? (
          <Alert severity="warning">
            Cette matrice a été créée à partir d'une matrice d'une ancienne version de Kairos, merci d'effectuer un
            changement de valeur dans la matrice <b>{parentMatrix.name}</b> pour pouvoir accéder à l'outil de
            synchronisation.
          </Alert>
        ) : (
          <Alert severity="warning">
            La matrice parente avec l'id {newMatrix?.basedOn} a été supprimée ou est introuvable.
          </Alert>
        )}
      </DialogContent>
      <DialogActions sx={{ m: 1 }}>
        <Button variant="outlined" onClick={() => setOpenModal(false)}>
          Annuler
        </Button>
        <Button
          variant="contained"
          type="submit"
          onClick={() => {
            sendNewMatrix();
          }}
        >
          Valider
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SyncDialog; 