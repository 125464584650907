import {
    INIT_TASKS,
    INIT_CALCUL_COSTS,
    INIT_EMPTY_COSTS,
    GET_TASKS_DATA,
    GET_LOT_DATA,
    ADD_TASK,
    ADD_CHARGES,
    CHANGE_VALUE,
    DELETE_TASKS,
    CALCULATE_VALUE,
    CALCUL_T_COSTS,
    CALCUL_ALL,
} from "../actions/types";


import {ITask} from "../../interface/task";

// Tools
import {roundDecimal} from "../../tools/mathRound";

let cost = {
    C: 0,
    RTUD: 0,
    RTUI: 0,
    QS: 0,
    QC: 0,
    QE: 0,
    RS: 0,
    RC: 0,
    RE: 0,
    D: 0,
    PC: 0,
    A: 0,
    G: 0,
};

let tasksChargesInit = {
    C: 0,
    RTUD: 0,
    RTUI: 0,
    QS: 0,
    QC: 0,
    QE: 0,
    RS: 0,
    RC: 0,
    RE: 0,
    D: 0,
    PC: 0,
    A: 0,
    G: 0,
};

interface IlotSynthesisState {
    tasks: Array<ITask>;
    tasksCharges: any;
    total?: number;
    charges?: any;
    PPR?: any;
    totalCharges?: any;
    lotCosts?: any;
}

const lotSynthesisState: IlotSynthesisState = {
    tasks: [],
    charges: {},
    total: 0,
    tasksCharges: tasksChargesInit,
    PPR: cost,
    totalCharges: cost,
    lotCosts: cost,
};

export default function lotSynthesisReducer(
    state = lotSynthesisState,
    action: any
) {
    let id: number;
    let copyTasks: Array<ITask> = [...state.tasks];
    let taskInputName: string;
    let inputValue: any;
    let charges = state.charges;
    let copyPPR = {...state.PPR};
    let totalTasksCharges = {...state.tasksCharges};
    let copyTotalCharges = {...state.totalCharges};
    let totalCostsPerProfile = {...state.lotCosts};
    let newTotal: number;

    switch (action.type) {

        case INIT_TASKS:
            copyTasks = action.payload.tasks;
            return {
                ...state,
                tasks: copyTasks,
            };
        case INIT_CALCUL_COSTS:
            /* Initialisation du tableau des coûts */
            return {
                ...state,
                tasksCharges: state.tasksCharges,
                charges: state.charges,
                PPR: state.PPR,
                totalCharges: state.totalCharges,
                lotCosts: state.lotCosts,
                total: state.total,
            };
        case INIT_EMPTY_COSTS:
            /* Initialisation du tableau des coûts à vide s'il n'y a pas de tâches dans un lot*/
            return {
                ...state,
                tasksCharges: lotSynthesisState.tasksCharges,
                PPR: lotSynthesisState.PPR,
                totalCharges: lotSynthesisState.totalCharges,
                lotCosts: lotSynthesisState.lotCosts,
                total: lotSynthesisState.total,
            };

        case GET_LOT_DATA:
            /* Récupération des données du lot */

            return {
                ...state,
                tasks: action.payload.tasks,
                tasksCharges: action.payload.tasksCharges,
                lotCosts: action.payload.lotCosts,
                PPR: action.payload.PPR,
                totalCharges: action.payload.totalCharges,
                total: action.payload.total,
            };
        case GET_TASKS_DATA:
            /* Récupération des données des tâches d'un lot*/
            return {
                ...state,
                tasks: copyTasks,
                tasksCharges: totalTasksCharges,
            };

        case ADD_TASK:
            let task: ITask = {
                _id: state.tasks.length ? state.tasks[state.tasks.length - 1]._id + 1 : 1,
                name: "",
                description: "",
                C: 0,
                RTUD: 0,
                RTUI: 0,
                QS: 0,
                QC: 0,
                QE: 0,
                RS: 0,
                RC: 0,
                RE: 0,
                D: 0,
                PC: 0,
                A: 0,
                G: 0,
                //PR: 0,
            };

            copyTasks.push(task);
            return {
                ...state,
                tasks: copyTasks,
            };

        case ADD_CHARGES:
            return {
                ...state,
                charges: action.payload.costs,
            };

        case DELETE_TASKS:
            /* Suppression des tâches => on reçoit le tableau de taches du lot et l'id du lot que l'utilisateur veut supprimer.
                  On supprime la tache du tableau et on retourne le tableau de tache sans la tache supprimée */

            let index = action.payload.taskId;
            copyTasks = action.payload.tasks;
            copyTasks.splice(index, 1);

            return {
                ...state,
                tasks: copyTasks,
            };


        case CHANGE_VALUE:
            id = action.payload.id;                 // id de la tâche
            taskInputName = action.payload.name;    // nom de l'input (ex: C, RTUD, RTUI, etc.)
            inputValue = action.payload.value;      // valeur de l'input

            // On récupère l'index de la tâche dans le tableau de tâches. On récupère la tâche correspondante et on change la valeur de l'input
            let taskIdx = copyTasks.findIndex((task: ITask) => task._id === id);

            // Si l'input est le nom ou la description de la tâche, on retourne directement le tableau de tâches car il n'y a pas de calcul à faire
            if (taskInputName === "name" || taskInputName === "description") {
                copyTasks[taskIdx][`${taskInputName}`] = inputValue;
                return {
                    ...state,
                    tasks: copyTasks,
                };
            }

            // Si le dernier caractère de l'input est une virgule ou un point on attend que l'utilisateur rentre un nombre après
            if (inputValue[inputValue.length - 1] === "." || inputValue[inputValue.length - 1] === ",") {
                copyTasks[taskIdx][`${taskInputName}`] = inputValue;
                return {
                    ...state,
                    tasks : copyTasks,
                };
            }

            // Si l'utilisateur rentre un nombre à virgule, on remplace la virgule par un point pour pouvoir le convertir en nombre
            inputValue = inputValue ? parseFloat(inputValue.replace(',', '.')) : 0;
            copyTasks[taskIdx][`${taskInputName}`] = Number(parseFloat(inputValue).toFixed(2)) ;

            // Permet de calculer le total des charges
            totalTasksCharges[taskInputName] = 0;
            copyTasks.forEach((copyTask: ITask) => {
                totalTasksCharges[taskInputName] += copyTask[taskInputName];
            });

            // Permet de calculer le total des Provision pour risque
            if (taskInputName !== "A" && taskInputName !== "G") {
                copyPPR[taskInputName] = Number(((totalTasksCharges[taskInputName] * charges["PPR"].rate) / 100).toFixed(2));
            }

            // Permet d'additionner les 2
            copyTotalCharges[taskInputName] = totalTasksCharges[taskInputName] + copyPPR[taskInputName];

            // Permet de faire en sorte qu"un coup total par personne soit calculé pour la garantie
            if (taskInputName === "G") {
                let gTotalCalculationBase = totalCostsPerProfile["RTUD"] + totalCostsPerProfile["RTUI"] + totalCostsPerProfile["QC"] + totalCostsPerProfile["QE"] + totalCostsPerProfile["RC"] + totalCostsPerProfile["RE"] + totalCostsPerProfile["D"]
                totalCostsPerProfile[taskInputName] = Number(((gTotalCalculationBase * totalTasksCharges[taskInputName] / 100)).toFixed(2));
            } else {
                totalCostsPerProfile[taskInputName] = Number((copyTotalCharges[taskInputName] * charges[taskInputName].cost).toFixed(2));
            }
            
            return {
                ...state,
                tasks: copyTasks,
                tasksCharges: totalTasksCharges,
                PPR: copyPPR,
                totalCharges: copyTotalCharges,
                lotCosts: totalCostsPerProfile,
            };

        case CALCULATE_VALUE:
            inputValue = action.payload.value;
            id = action.payload.id;

            // Permet d'initialiser à 0
            Object.keys(totalTasksCharges).forEach((key) => {
                totalTasksCharges[key] = 0;
            });
            newTotal = 0;
            copyTasks.forEach((copyTask: ITask | any) => {
                if (copyTask._id === id) {
                    // BOUTON qui équilibre les calcul si on veut ?
                    try {
                        copyTask.QS = roundDecimal(
                            ((copyTask.RTUD + copyTask.RTUI) * charges.QS.rate) / 100,
                            2
                        );

                        copyTask.QC = roundDecimal(
                            ((copyTask.RTUD + copyTask.RTUI) * charges.QE.rate) / 100,
                            2
                        );
                        copyTask.QE = roundDecimal(
                            ((copyTask.RTUD + copyTask.RTUI) * charges.QE.rate) / 100,
                            2
                        );
                        copyTask.RS = roundDecimal(
                            ((copyTask.RTUD + copyTask.RTUI) * charges.RS.rate) / 100,
                            2
                        );
                        copyTask.RC = roundDecimal(
                            ((copyTask.RTUD + copyTask.RTUI) * charges.RC.rate) / 100,
                            2
                        );
                        copyTask.RE = roundDecimal(
                            ((copyTask.RTUD + copyTask.RTUI) * charges.RE.rate) / 100,
                            2
                        );
                        copyTask.D = roundDecimal(
                            ((copyTask.RTUD) * charges.D.rate) / 100,
                            2
                        );
                        copyTask.A = roundDecimal(
                            (copyTask.RTUD + copyTask.RTUI) * charges.A.cost,
                            2
                        );
                        copyTask.PC = roundDecimal(
                            ((copyTask.C +
                                    copyTask.RTUD +
                                    copyTask.RTUI +
                                    copyTask.QC +
                                    copyTask.QE +
                                    copyTask.D) *
                                charges.PC.rate) /
                            100,
                            2
                        );
                        copyTask.G = roundDecimal(
                            ((copyTask.RTUD + copyTask.RTUI + copyTask.QR + copyTask.QC) * charges.G.rate) / 100,
                            2
                        );
                        //copyTask.PPR = roundDecimal((copyTask.RTUD + copyTask.RTUI + copyTask.QS + copyTask.QC + copyTask.QE + copyTask.RS + copyTask.RC + copyTask.RE + copyTask.D + copyTask.QE + copyTask.D + copyTask.PC) * charges.PPR.rate / 100, 2)
                        copyTask.C = roundDecimal(
                            ((copyTask.RTUD + copyTask.RTUI) * charges.C.rate) / 100,
                            2
                        );
                    } catch (e) {
                        console.error(e);
                    }
                }
                Object.keys(totalTasksCharges).forEach((key) => {
                    if (key !== "name" && key !== "description") {
                        totalTasksCharges[key] += copyTask[key];
                        totalTasksCharges[key] = roundDecimal(totalTasksCharges[key], 2);
                        if (key !== "G") {
                            copyPPR[key] = roundDecimal(
                                (totalTasksCharges[key] * charges["PPR"].rate) / 100,
                                2
                            );
                            copyTotalCharges[key] = roundDecimal(
                                totalTasksCharges[key] + copyPPR[key],
                                2
                            );
                            totalCostsPerProfile[key] = roundDecimal(
                                copyTotalCharges[key] * charges[key].cost,
                                2
                            );
                            newTotal += roundDecimal(totalCostsPerProfile[key], 2);
                        }
                    }
                });
            });
            return {
                ...state,
                tasks: copyTasks,
                tasksCharges: totalTasksCharges,
                PPR: copyPPR,
                totalCharges: copyTotalCharges,
                lotCosts: totalCostsPerProfile,
                total: roundDecimal(newTotal, 2),
            };

        case CALCUL_T_COSTS:
            newTotal = 0;
            Object.keys(totalTasksCharges).forEach((key) => {
                newTotal = roundDecimal(newTotal, 2);
                if (key !== "name" && key !== "description") {
                    {
                        newTotal += roundDecimal(totalCostsPerProfile[key], 2);
                    }
                }
            });
            return {
                ...state,
                total: roundDecimal(newTotal, 2),
                // total: newTotal
            };

        case CALCUL_ALL:
            // Permet d'initialiser à 0
            Object.keys(totalTasksCharges).forEach((key) => {
                totalTasksCharges[key] = 0;
            });
            newTotal = 0;
            copyTasks.forEach((copyTask: ITask | any) => {
                // BOUTON qui équilibre les calcul si on veut ?
                try {
                    charges.C.rate !== undefined
                        ?
                        (copyTask.C = roundDecimal(
                            ((copyTask.RTUD + copyTask.RTUI) * charges.C.rate) / 100,
                            2
                        ))
                        : (copyTask.C = 0);
                    charges.QS.rate !== undefined
                        ? (copyTask.QS = roundDecimal(
                            ((copyTask.RTUD + copyTask.RTUI) * charges.QS.rate) / 100,
                            2
                        ))
                        : (copyTask.QS = 0);
                    charges.QC.rate !== undefined
                        ? (copyTask.QC = roundDecimal(
                            ((copyTask.RTUD + copyTask.RTUI) * charges.QC.rate) / 100,
                            2
                        ))
                        : (copyTask.QC = 0);
                    charges.QE.rate !== undefined
                        ? (copyTask.QE = roundDecimal(
                            ((copyTask.RTUD + copyTask.RTUI) * charges.QE.rate) / 100,
                            2
                        ))
                        : (copyTask.QE = 0);
                    charges.RS.rate !== undefined
                        ? (copyTask.RS = roundDecimal(
                            ((copyTask.RTUD + copyTask.RTUI) * charges.RS.rate) / 100,
                            2
                        ))
                        : (copyTask.RS = 0);
                    charges.RC.rate !== undefined
                        ? (copyTask.RC = roundDecimal(
                            ((copyTask.RTUD + copyTask.RTUI) * charges.RC.rate) / 100,
                            2
                        ))
                        : (copyTask.RC = 0);
                    charges.RE.rate !== undefined
                        ? (copyTask.RE = roundDecimal(
                            ((copyTask.RTUD + copyTask.RTUI) * charges.RE.rate) / 100,
                            2
                        ))
                        : (copyTask.RE = 0);
                    charges.D.rate !== undefined
                        ? (copyTask.D = roundDecimal(
                            ((copyTask.RTUD) * charges.D.rate) / 100,
                            2
                        ))
                        : (copyTask.D = 0);

                        copyTask.A !== undefined ?
                        //  (copyTask.A = roundDecimal(
                        //   ((copyTask.RTUD + copyTask.RTUI) * charges.A.cost) / 100,
                        //   2
                        // ))

                        (copyTask.A = roundDecimal(
                            (copyTask.A),
                            2
                        )) : (copyTask.A = 1);

                    copyTotalCharges.A = roundDecimal(copyTask.A, 2)


                    charges.PC.rate !== undefined
                        ? (copyTask.PC = roundDecimal(
                            ((copyTask.C +
                                    copyTask.RTUD +
                                    copyTask.RTUI +
                                    copyTask.QC +
                                    copyTask.QE +
                                    copyTask.D) *
                                charges.PC.rate) /
                            100,
                            2
                        ))
                        : (copyTask.PC = 0);


                    charges.G.rate !== undefined
                        ? (copyTask.G = roundDecimal(
                            ((copyTask.RTUD +
                                    copyTask.RTUI +
                                    copyTask.QC +
                                    copyTask.QE ) *
                                charges.G.rate) /
                            100,
                            2
                        ))
                        : (copyTask.G = 0);

                    copyTotalCharges.G = roundDecimal(copyTask.G, 2);


                    if(charges.PPR !== 0){
                        charges.PPR.rate !== undefined
                        ? (copyTask.PPR = roundDecimal(
                            ((copyTask.C +
                                    copyTask.RTUD +
                                    copyTask.RTUI +
                                    copyTask.QC +
                                    copyTask.QE +
                                    copyTask.D +
                                    copyTask.PC) *
                                charges.PPR.rate) /
                            100,
                            2
                        ))
                        : (copyTask.PPR = 0);
                    }
                    
                } catch (e) {
                    console.error(e);
                }
                Object.keys(totalTasksCharges).forEach((key) => {
                    if (key !== "name" && key !== "description") {
                        totalTasksCharges[key] += copyTask[key];
                        totalTasksCharges[key] = roundDecimal(totalTasksCharges[key], 2);

                        if(charges.PPR !== 0){
                            if (key !== "G" && key !== "A") {
                                copyPPR[key] = roundDecimal(
                                    (totalTasksCharges[key] * charges["PPR"].rate) / 100,
                                    2
                                );
                                copyTotalCharges[key] = roundDecimal(
                                    totalTasksCharges[key] + copyPPR[key],
                                    2
                                );
                                if (charges[key].cost === undefined) {
                                    totalCostsPerProfile[key] = 0;
                                } else if (charges[key].cost) {
                                    totalCostsPerProfile[key] = roundDecimal(
                                        copyTotalCharges[key] * charges[key].cost,
                                        2
                                    );
                                }
                                newTotal += roundDecimal(totalCostsPerProfile[key], 2);
    
                            } else if (key === "A") {
                                totalCostsPerProfile[key] = totalTasksCharges[key] * charges["A"].cost;
                                // newTotal += roundDecimal(totalCostsPerProfile[key], 2);
                            } else if (key === "G") {
    
                                // définition d'une variable utilisé pour calculer le coût Total de la garantie plus tard
                                let gTotalCalculationBase = roundDecimal(totalCostsPerProfile["RTUD"] + totalCostsPerProfile["RTUI"] + totalCostsPerProfile["QC"] + totalCostsPerProfile["QE"] + totalCostsPerProfile["RC"] + totalCostsPerProfile["RE"] + totalCostsPerProfile["D"], 2);
    
                                totalCostsPerProfile[key] = roundDecimal((gTotalCalculationBase * totalTasksCharges[key] / 100), 2);
                                // newTotal += roundDecimal(totalCostsPerProfile[key], 2);
                            }
                        }
                        else{
                            copyPPR[key] = 0;
                            copyTotalCharges[key] = totalTasksCharges[key];
                            if (key !== "G" && key !== "A") {
                                if (charges[key].cost === undefined) {
                                    totalCostsPerProfile[key] = 0;
                                } else if (charges[key].cost) {
                                    totalCostsPerProfile[key] = roundDecimal(
                                        copyTotalCharges[key] * charges[key].cost,
                                        2
                                    );
                                }
                                newTotal += roundDecimal(totalCostsPerProfile[key], 2);
    
                            } else if (key === "A") {
                                totalCostsPerProfile[key] = totalTasksCharges[key] * charges["A"].cost;
                                // newTotal += roundDecimal(totalCostsPerProfile[key], 2);
                            } else if (key === "G") {
    
                                // définition d'une variable utilisé pour calculer le coût Total de la garantie plus tard
                                let gTotalCalculationBase = roundDecimal(totalCostsPerProfile["RTUD"] + totalCostsPerProfile["RTUI"] + totalCostsPerProfile["QC"] + totalCostsPerProfile["QE"] + totalCostsPerProfile["RC"] + totalCostsPerProfile["RE"] + totalCostsPerProfile["D"], 2);
    
                                totalCostsPerProfile[key] = roundDecimal((gTotalCalculationBase * totalTasksCharges[key] / 100), 2);
                                // newTotal += roundDecimal(totalCostsPerProfile[key], 2);
                            }
                        }
                        
                    }
                });
            });
            return {
                ...state,
                tasks: copyTasks,
                tasksCharges: totalTasksCharges,
                PPR: copyPPR,
                totalCharges: copyTotalCharges,
                lotCosts: totalCostsPerProfile,
                total: newTotal,
            };
        default:
            return state;
    }
}
