// React
import { useEffect, useState } from "react";

// Redux
import { useDispatch } from "react-redux";
import { sessionStart } from "../redux/actions";

// MSAL (Microsoft Authentication Library)
import { useMsal } from "@azure/msal-react";
import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";

// Config
import { protectedResources } from "../config/authConfig";

// React router dom
import { useNavigate, useLocation } from "react-router-dom";

// Material UI
import { Box } from "@mui/system";
import { Paper, Typography } from "@mui/material";

//Logo
import logo from "../img/logo/Kairos-removebg-preview.webp";

// Types
interface LocationFrom {
  pathname: string;
}

interface APIResponse {
  token: string;
  username: string;
}

/**
 * Fait un appel au backend pour récupérer le token et le username de l'utilisateur
 * @param accessToken access token de l'utilisateur récupéré par MSAL
 * @param apiEndpoint endpoint de l'API
 * @returns token et username de l'utilisateur
 */
const callApi = async (accessToken: any, apiEndpoint: any) => {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers,
  };

  const response = await fetch(apiEndpoint, options);

  if (!response.ok) {
    throw new Error(`API request failed with status ${response.status}`);
  }

  return (await response.json()) as APIResponse;
};

/**
 * Composant qui permet de récupérer le token et le username de l'utilisateur et de les stocker dans le store
 * @param from location de la page d'où l'utilisateur vient
 */
function ProtectedComponent({ from }: { from: LocationFrom }) {
  const { instance, inProgress, accounts } = useMsal();
  const [apiData, setApiData] = useState<APIResponse | null>(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const accessTokenRequest = {
      scopes: protectedResources.apiLoginAzur.scopes,
      account: accounts[0],
    };
    if (!apiData && inProgress === InteractionStatus.None) {
      instance
        .acquireTokenSilent(accessTokenRequest)
        .then((accessTokenResponse) => {
          // Acquire token silent success
          let accessToken = accessTokenResponse.accessToken;
          // Call your API with token
          callApi(accessToken, protectedResources.apiLoginAzur.endpoint).then(
            (response: APIResponse) => {
              setApiData(response);
              dispatch(sessionStart(response.token, response.username));

              navigate(from?.pathname || "/", { replace: true });
            }
          );
        })
        .catch((error) => {
          if (
            error instanceof InteractionRequiredAuthError ||
            accessTokenRequest.account === undefined
          ) {
            instance.acquireTokenRedirect(accessTokenRequest);
          }
          console.error(error);
        });
    }
  }, [instance, accounts, inProgress, apiData]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Paper
        sx={{
          height: "fit-content",
          width: "fit-content",
          p: 10,
          borderRadius: 5,
        }}
      >
        <Typography variant="h6" component="div" sx={{ textAlign: "center" }}>
          Authentification en cours...
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 5,
          }}
        >
          <Box
            sx={{
              animation: "breathe 2s infinite",
              "@keyframes breathe": {
                "0%, 100%": {
                  transform: "scale(1)",
                },
                "50%": {
                  transform: "scale(1.05)",
                },
              },
            }}
          >
            {" "}
            <img src={logo} alt="logo" style={{ width: "100px" }} />
          </Box>
        </Box>
      </Paper>
    </div>
  );
}

export default function AzureAuth() {
  const location = useLocation();

  return (
    <ProtectedComponent
      from={(location.state as { from: LocationFrom })?.from}
    />
  );
}
