export const SESSION_START = "SESSION_START";
export const SESSION_END = "SESSION_END";
export const ADD_TASK = "ADD_TASK";
export const CHANGE_VALUE = "CHANGE_VALUE";
export const CALCULATE_VALUE = "CALCULATE_VALUE";
export const ADD_CHARGES = "ADD_CHARGES";
export const CALCUL_T_COSTS = "CALCUL_T_COSTS";
export const CALCUL_ALL = "CALCUL_ALL";
export const DELETE_TASKS = "DELETE_TASKS";
export const GET_LOT_DATA = "GET_LOT_DATA";
export const INIT_TASKS = "INIT_TASKS";
export const GET_TASKS_DATA = "GET_TASKS_DATA";
export const INIT_CALCUL_COSTS = "INIT_CALCUL_COSTS";
export const INIT_EMPTY_COSTS = "INIT_EMPTY_COSTS";
export const SHOW_SNACKBAR = 'SHOW_SNACKBAR';
export const HIDE_SNACKBAR = 'HIDE_SNACKBAR';