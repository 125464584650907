import { urlAPI } from "../config/config";

const handleApiResponse = async (
  response: Response,
  setState: any,
  sessionEnd: any,
  setError: any = null
) => {
  if (response.status === 200) {
    const jsonResponse = await response.json();
    setState(jsonResponse.message);
  } else if (response.status === 204) {
    setState([]);
  } else if (response.status === 401) {
    sessionEnd();
  } else {
    const errorResponse = await response.json();
    if (setError) {
      setError(errorResponse);
    } else {
      console.error(errorResponse);
    }
  }
};

const fetchWithHandling = async (
  token: string,
  endPoint: string,
  setState: any,
  sessionEnd: any,
  setError: any = null
) => {
  try {
    const response = await fetch(endPoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${token}`,
      },
    });
    await handleApiResponse(response, setState, sessionEnd, setError);
  } catch (error) {
    if (setError) {
      setError(error);
    } else {
      console.error(error);
    }
  }
};

export const getOne = async (
  token: string,
  urlRouter: string,
  id: string,
  setState: any,
  sessionEnd: any,
  setError: any = null
) => {
  const endPoint = `${urlAPI}${urlRouter}${id}`;
  await fetchWithHandling(token, endPoint, setState, sessionEnd, setError);
};

export const getAll = async (
  token: string,
  urlRouter: string,
  setState: any,
  sessionEnd: any,
  setError: any = null
) => {
  const endPoint = `${urlAPI}${urlRouter}`;
  await fetchWithHandling(token, endPoint, setState, sessionEnd, setError);
};

export const getWithFilter = async (
  token: string,
  urlRouter: string,
  keyword: string,
  setState: any,
  sessionEnd: any,
  setError: any = null
) => {
  const formattedKeyword = encodeURIComponent(keyword).replace(/\./g, '%2E');
  const endPoint = `${urlAPI}${urlRouter}?keyword=${formattedKeyword}`;
  await fetchWithHandling(token, endPoint, setState, sessionEnd, setError);
};

export const fetchGetProtect = async (
  token: string,
  urlRouter: string,
  id: string = ""
) => {
  const apiEndPoint = `${urlAPI}${urlRouter}${id}`;
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${token}`,
    },
  };
  return await fetch(apiEndPoint, options);
};
