import { SHOW_SNACKBAR, HIDE_SNACKBAR } from "../actions/types";

interface IAction {
    type: string;
    payload: {
        message: string;
        severity: string;
    }
}
const initialState = {
  open: false,
  message: '',
  severity: 'success', // 'error', 'warning', 'info', 'success'
};

const snackbarReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case SHOW_SNACKBAR:
      return {
        ...state,
        open: true,
        message: action.payload.message,
        severity: action.payload.severity,
      };
    case HIDE_SNACKBAR:
      return {
        ...state,
        open: false,
        message: '',
        severity: 'success',
      };
    default:
      return state;
  }
};

export default snackbarReducer;