// Material UI
import { Typography, Chip, Box, IconButton } from "@mui/material";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";
import { green } from '@mui/material/colors';
import Checkbox from "@mui/material/Checkbox";

// Interface
import { MatriceWrapperClientsData, ICost, ICostMerge, MatriceMergeData, ICostChanges } from "../../interface/matrice";

// Icons
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import ClearIcon from "@mui/icons-material/Clear";

// Components
import MergeActionButton from "./MergeActionsButton";

const flexCenter: SxProps<Theme> = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
};

/**
 * Affiche un chip contenant :
 * - La valeur actuelle de la matrice si aucune modification n'a été faite
 * - La valeur actuelle de la matrice et la plus ancienne si une modification a été faite
 * @param matrixCell La cellule de la matrice
 * @param type Le type de la cellule (rate ou cost)
 * @param changes Les changements effectués sur la matrice
 * @param mergeObjectCell L'objet de merge de la cellule (0 : pas de merge, 1 : merge, 2 : merge conflicted)
 */
function DisplayChip({
  matrixCell,
  type,
  changes,
  mergeObjectCell,
}: {
  matrixCell: ICost;
  type: "rate" | "cost";
  changes: ICostChanges;
  mergeObjectCell: ICostMerge;
}) {
  let symbol = type === "rate" ? "%" : "€";
  return (
    <Stack direction="row" alignItems="center" justifyContent="center">
      {(mergeObjectCell[type] === 1 || mergeObjectCell[type] === 2) && changes[type].length ? (
        <Chip
          label={
            <Stack direction="row" justifyContent="center" alignItems="center">
              {matrixCell[type] !== undefined && <div>{changes[type][0] + symbol}</div>}
              <KeyboardDoubleArrowRightIcon sx={{ opacity: 0.5, ml: 1, mr: 0.5 }} />
              <Chip label={matrixCell[type] + symbol} sx={{ my: 1 }} color="success" />
            </Stack>
          }
          sx={{
            "&>span": { pr: 0 },
            m: 1,
          }}
        />
      ) : (
        <Chip label={matrixCell[type] + symbol} sx={{ m: 1, bgcolor: mergeObjectCell[type] === 3 ? green[100] : "" }} />
      )}
    </Stack>
  );
}

/**
 * Affiche la ligne de taux ou de coût d'une matrice
 * @param cell La cellule de la matrice
 * @param indexRow L'index de la ligne de la cellule
 * @param indexCell L'index de la colonne de la cellule
 * @param type Le type de la cellule (rate ou cost)
 * @param newMatrix La matrice actuelle
 * @param parentMatrix La matrice parente
 * @param merge La fonction pour merger les modifications
 * @param mergeObjectCell L'objet de merge de la cellule (0 : pas de merge, 1 : merge, 2 : merge conflicted)
 * @param resetMerge La fonction pour reset le merge
 */
function TableRow({
  cell,
  indexRow,
  indexCell,
  type,
  newMatrix,
  parentMatrix,
  merge,
  mergeObjectCell,
  resetMerge,
}: {
  cell: any;
  indexRow: number;
  indexCell: number;
  type: "rate" | "cost";
  newMatrix: MatriceWrapperClientsData;
  parentMatrix: MatriceWrapperClientsData;
  merge: Function;
  mergeObjectCell: ICostMerge;
  resetMerge: Function;
}) {
  return cell[type] !== undefined ? (
    <Grid container columns={22} sx={{ position: "relative" }}>
      <Grid item xs={3} sx={flexCenter}>
        <Typography variant="subtitle2">{type === "rate" ? "Taux" : "Coût"}</Typography>
      </Grid>
      <Grid item xs={6}>
        <DisplayChip
          matrixCell={cell}
          type={type}
          changes={newMatrix.changes[indexRow].costs[indexCell]}
          mergeObjectCell={mergeObjectCell}
        />
      </Grid>
      <Grid item xs={2}>
        <Checkbox
          color="primary"
          checked={cell.isChoosen ? cell.isChoosen : false}
          inputProps={{
            "aria-label": "select all desserts",
          }}
        />
      </Grid>
      <Grid item xs={3}>
        {parentMatrix?.data && parentMatrix?.changes && (
          <MergeActionButton
            currentData={cell[type]}
            parentData={parentMatrix.data[indexRow].costs[indexCell][type]}
            indexRow={indexRow}
            indexCell={indexCell}
            type={type}
            mergeAction={merge}
            mergeObjectCell={mergeObjectCell}
          />
        )}
      </Grid>
      <Grid item xs={6}>
        {parentMatrix?.data && (
          <DisplayChip
            matrixCell={parentMatrix?.data[indexRow]?.costs[indexCell]}
            type={type}
            changes={parentMatrix.changes[indexRow].costs[indexCell]}
            mergeObjectCell={mergeObjectCell}
          />
        )}
      </Grid>
      <Grid item xs={2}>
        <Checkbox
          color="primary"
          checked={parentMatrix.data[indexRow].costs[indexCell].isChoosen ? parentMatrix.data[indexRow].costs[indexCell].isChoosen : false}
          inputProps={{
            "aria-label": "select all desserts",
          }}
        />
      </Grid>
      {mergeObjectCell[type] === 3 && (
        <Box sx={{ position: "absolute", right: 5, height: "100%", ...flexCenter }}>
          <IconButton
            onClick={() => {
              resetMerge(false, indexRow, indexCell, type);
            }}
          >
            <ClearIcon fontSize="small" />
          </IconButton>
        </Box>
      )}
    </Grid>
  ) : null;
}

/**
 * Affiche la table de fusion des modifications de la matrice actuelle et de la matrice parente
 * @param newMatrix La matrice actuelle
 * @param parentMatrix La matrice parente
 * @param setNewMatrix La fonction pour modifier la matrice actuelle
 * @param merge La fonction pour merger les modifications
 * @param mergeObject L'objet de merge de la matrice
 */
const SyncTable = ({
  newMatrix,
  parentMatrix,
  merge,
  mergeObject,
  resetMerge,
}: {
  newMatrix: MatriceWrapperClientsData;
  parentMatrix: MatriceWrapperClientsData;
  merge: Function;
  mergeObject: MatriceMergeData[];
  resetMerge: Function;
}) => {
  return parentMatrix?.changes !== undefined && parentMatrix?.data !== undefined ? (
    <Stack direction="column" alignItems="center" justifyContent="center">
      {newMatrix.data.map(
        (row: any, indexRow: number) =>
          mergeObject[indexRow].display && (
            <Grid container sx={{ mt: 2, bgcolor: "whitesmoke", borderRadius: 5 }} key={indexRow}>
              <Grid item xs={2} sx={flexCenter}>
                <Typography variant="subtitle1">{row.name}</Typography>
              </Grid>

              {row.costs.length && (
                <Grid item xs={10}>
                  {row.costs.map((cell: any, indexCell: number) => (
                    <div key={indexCell}>
                      <Grid container>
                        <Grid item xs={2} sx={flexCenter}>
                          <Typography variant="subtitle2" sx={{ textTransform: "capitalize" }}>
                            {cell.level}
                          </Typography>
                        </Grid>
                        <Grid item xs={10}>
                          <TableRow
                            cell={cell}
                            indexRow={indexRow}
                            indexCell={indexCell}
                            type="rate"
                            newMatrix={newMatrix}
                            parentMatrix={parentMatrix}
                            merge={merge}
                            mergeObjectCell={mergeObject[indexRow].costs[indexCell]}
                            resetMerge={resetMerge}
                          />

                          {cell?.rate !== undefined && cell?.cost !== undefined && (
                            <Divider sx={{ borderStyle: "dashed" }} />
                          )}

                          <TableRow
                            cell={cell}
                            indexRow={indexRow}
                            indexCell={indexCell}
                            type="cost"
                            newMatrix={newMatrix}
                            parentMatrix={parentMatrix}
                            merge={merge}
                            mergeObjectCell={mergeObject[indexRow].costs[indexCell]}
                            resetMerge={resetMerge}
                          />
                        </Grid>
                      </Grid>
                      {indexCell < row.costs.length - 1 && <Divider />}
                    </div>
                  ))}
                </Grid>
              )}
            </Grid>
          )
      )}
    </Stack>
  ) : null;
};

export default SyncTable;
