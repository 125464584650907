// MaterialUI
import { Card, Typography } from "@mui/material";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import { SxProps, Theme } from "@mui/system";

// Icons
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import FileCopyIcon from "@mui/icons-material/FileCopy";
// import VisibilityIcon from '@mui/icons-material/Visibility';

// Interface
import { MatriceWrapperClientsData } from "../../interface/matrice";

// Redux
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers/index";

const sxProp: SxProps<Theme> = {
  p: 2,
  height: 100,
  display: "-webkit-box",
  WebkitBoxOrient: "vertical" as any,
  WebkitLineClamp: 4 as any,
  overflow: "hidden",
};

/** Affiche la box d'une matrice avec son nom, sa description, ses clients associés et ses actions */
const MatrixBox = ({
  matrice,
  handleClientClick,
  setOpenModal,
  handleDeleteConfirmation,
  handleDuplicateConfirmation,
}: {
  matrice: MatriceWrapperClientsData;
  handleClientClick: Function;
  setOpenModal: Function;
  handleDeleteConfirmation: Function;
  handleDuplicateConfirmation: Function;
}) => {
  const author = useSelector((state: RootState) => {
    return state.user.username;
  });

  return (
    <Card key={matrice._id} sx={{ width: "19rem", height: "26rem", m: 2 }}>
      <CardContent sx={{ p: 0, mb: 2 }}>
        <Box sx={{ backgroundColor: "#ff9800", width: "100%", px: 2, py: 1 }}>
          <Tooltip title={matrice.name}>
            <Typography
              variant="h5"
              component="div"
              sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
            >
              {matrice.name}
            </Typography>
          </Tooltip>
          <Typography color="text.secondary">par {matrice.creator}</Typography>
        </Box>
        <Box sx={sxProp}>
          {matrice?.desc ? (
            <Tooltip title={matrice.desc}>
              <Typography variant="body2">{matrice.desc}</Typography>
            </Tooltip>
          ) : (
            <Typography variant="body2" sx={{ textAlign: "center", width: "100%", opacity: 0.5 }}>
              Aucune description
            </Typography>
          )}
        </Box>
        <Divider variant="middle" />
        <Box>
          <Typography variant="subtitle2" sx={{ p: 2 }}>
            Client associés
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              height: 120,
              overflowY: "auto",
            }}
          >
            <Stack
              direction="row"
              flexWrap="wrap"
              justifyContent="flex-start"
              alignItems="flex-start"
              alignContent="flex-start"
              sx={{ width: "100%", px: 1 }}
            >
              {matrice?.clients && matrice.clients.length > 0 ? (
                matrice.clients.map((client: any) => {
                  return (
                    <Chip
                      key={client._id}
                      label={client.name}
                      avatar={<Avatar>{client.name.charAt(0)}</Avatar>}
                      onClick={() => handleClientClick(client.name)}
                      sx={{ mx: 1, my: 0.5, flexShrink: 0, maxWidth: "100%" }}
                    />
                  );
                })
              ) : (
                <Typography variant="body2" sx={{ textAlign: "center", width: "100%", opacity: 0.5 }}>
                  Aucun client associé
                </Typography>
              )}
            </Stack>
          </Box>
        </Box>
      </CardContent>
      <Divider variant="middle" />
      <CardActions disableSpacing>
        <IconButton
          aria-label="edit matrice"
          onClick={() => (author === matrice.creator ? setOpenModal(matrice) : null)}
          sx={{
            opacity: author === matrice.creator ? 1 : 0.25,
            cursor: author === matrice.creator ? "pointer" : "not-allowed",
          }}
        >
          <EditIcon />
        </IconButton>
        <IconButton
          aria-label="duplicate matrice"
          onClick={() => handleDuplicateConfirmation(matrice._id, matrice.name)}
        >
          <FileCopyIcon />
        </IconButton>
        <IconButton
          aria-label="delete matrice"
          onClick={() => (author === matrice.creator ? handleDeleteConfirmation(matrice._id) : null)}
          sx={{
            opacity: author === matrice.creator ? 1 : 0.25,
            cursor: author === matrice.creator ? "pointer" : "not-allowed",
          }}
        >
          <DeleteIcon />
        </IconButton>
        {/* <IconButton
          aria-label="open matrice"
          onClick={() => (setOpenModal(matrice))}
        >
          <VisibilityIcon />
        </IconButton> */}
      </CardActions>
    </Card>
  );
};

export default MatrixBox;