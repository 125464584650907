import { urlAPI } from "../config/config";

export const fetchPost = async (urlRouter: string, body: any) => {
  let apiEndPoint = `${urlAPI}${urlRouter}`;
  const options = {
    method: "POST",
    headers: { "Content-Type": "application/json; charset=UTF-8" },
    body: body,
  };
  return fetch(apiEndPoint, options);
};

export const fetchPostProtect = async (
  token: string,
  urlRouter: string,
  body: any
) => {
  let apiEndPoint = `${urlAPI}${urlRouter}`;

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      Authorization: "Bearer " + token,
    },
    body: body,
  };

  return fetch(apiEndPoint, options);

};

export const duplicateMatrix = async (
  token: string,
  urlRouter: string,
  matrix: string[],
) => {
  let apiEndPoint = `${urlAPI}${urlRouter}`;
  const options = {
      method: "POST", 
      headers: {
          "Content-Type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ 
        _id: matrix[0],
        name : matrix[1] + " (copy)"
       }),
  };
  return fetch(apiEndPoint, options);
}