import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router';

export function useCallbackPrompt(when: boolean, setLotId: Function): [boolean, () => void, () => void] {
  const navigate = useNavigate();
  const location = useLocation();
  const [showPrompt, setShowPrompt] = useState(false);
  const [lastLocation, setLastLocation] = useState<any>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  
  const cancelNavigation = useCallback(() => {
    setShowPrompt(false);
  }, []);

  useEffect(() => {
    if (when && location !== lastLocation) {
      setShowPrompt(true);
      setLastLocation(location);
    }
  }, [location]);

  const confirmNavigation = useCallback(() => {
    setShowPrompt(false);
    setConfirmedNavigation(true);
  }, []);

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      setLotId("");
      navigate(lastLocation.pathname);
      setConfirmedNavigation(false);
    }
  }, [confirmedNavigation, lastLocation]);

  return [showPrompt, confirmNavigation, cancelNavigation];
}
