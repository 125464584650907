// React
import { useState, Fragment } from "react";

// Material UI
import {
  Typography,
  Divider,
  Menu,
  Button,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Item from "@mui/material/ListItem";
import Fade from '@mui/material/Fade';

// Icons
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";

// Tools
import { handleFilterClick, handleFilterClose, handleFilterChange, handleSelectAll } from "../../../tools/history";

const UserFilter = ({
  personName,
  setPersonName,
  names,
}: {
  personName: string[];
  setPersonName: React.Dispatch<React.SetStateAction<string[]>>;
  names: string[];
}) => {
  const [personFilterAnchorEl, setPersonFilterAnchorEl] = useState<null | HTMLElement>(null);

  const personFilterOpen = Boolean(personFilterAnchorEl);

  return (
    <Fragment>
      <Menu
        id="person-filter"
        anchorEl={personFilterAnchorEl}
        open={personFilterOpen}
        onClose={handleFilterClose(setPersonFilterAnchorEl)}
        MenuListProps={{
          "aria-labelledby": "person-filter",
        }}
        TransitionComponent={Fade}
        PaperProps={{
            style: {
              maxHeight: 600,
              width: 'fit-content',
            },
          }}
      >
          <FormControlLabel
            control={
              <Checkbox
                checked={personName.length === names.length}
                indeterminate={personName.length > 0 && personName.length < names.length}
                onChange={handleSelectAll(setPersonName, names, personName)}
                size="small"
              />
            }
            label={<span style={{ fontWeight: 500 }}>Select All</span>}
            sx={{
                pl: 2,
                pb: .5,
            }}
          />

        <Divider sx={{mb: .5}} />

        {names.map((name) => (
          <MenuItem key={name} dense style={{ padding: "0" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={personName.includes(name)}
                  onChange={handleFilterChange(setPersonName, personName)(name)}
                  size="small"
                />
              }
              label={<span style={{ textTransform: "none" }}>{name}</span>}
              sx={{
                pl: 5,
              }}
            />
          </MenuItem>
        ))}
        <Divider />
        <Button
          size="small"
          onClick={() => {
            setPersonName([]);
          }}
          sx={{ ml: 2 }}
        >
          Clear ({personName.length})
        </Button>
      </Menu>
      <Item sx={{ width: 120 }}>
        <Button
          id="person-filter"
          aria-controls="person-filter"
          aria-haspopup="true"
          aria-expanded={personFilterOpen ? "true" : undefined}
          onClick={handleFilterClick(setPersonFilterAnchorEl)}
          endIcon={<ExpandMoreRoundedIcon />}
        >
          <Typography variant="subtitle2">Utilisateur</Typography>
        </Button>
      </Item>
    </Fragment>
  );
};

export default UserFilter;