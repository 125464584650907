// Material UI
import { Typography, Chip } from "@mui/material";
import Stack from "@mui/material/Stack";

// Interface
import { Fragment, useState } from "react";

// Icons
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import { ICostMerge } from "../../interface/matrice";

/**
 * Affiche les actions de merge possible
 * - merge : Si les valeurs sont différentes et que les changements de la matrice actuelle sont vides et que les changements de la matrice parente ne sont pas vides
 * - conflit : Si les valeurs sont différentes et que les changements de la matrice actuelle et de la matrice parente ne sont pas vides
 * Si un conflit est présent, on affiche un chip avec les deux valeurs et on peut choisir laquelle garder
 */
const MergeActionButton = ({
  currentData,
  parentData,
  indexRow,
  indexCell,
  type,
  mergeAction,
  mergeObjectCell,
}: {
  currentData: number | undefined;
  parentData: number | undefined;
  indexRow: number;
  indexCell: number;
  type: "rate" | "cost";
  mergeAction: Function;
  mergeObjectCell: ICostMerge;
}) => {
  const [mergeResolve, setMergeResolve] = useState(false);

  return mergeObjectCell[type] === 2 ? (
    <Stack direction="row" alignItems="center" justifyContent="center">
      <Chip
        label={
          <Stack direction="row" justifyContent="center" alignItems="center">
            {mergeResolve ? (
              <Fragment>
                <Chip
                  label={currentData + (type === "rate" ? "%" : "€")}
                  sx={{ my: 1, width: "fit-content" }}
                  color="error"
                  onClick={() => {
                    mergeAction(indexRow, indexCell, type, 2)
                  }}
                />
                <Chip
                  label={parentData + (type === "rate" ? "%" : "€")}
                  sx={{ my: 1 }}
                  color="error"
                  onClick={() => mergeAction(indexRow, indexCell, type, 1)}
                />
              </Fragment>
            ) : (
              <Typography variant="subtitle2" sx={{ mx: 1 }}>
                conflict
              </Typography>
            )}
          </Stack>
        }
        sx={{ m: 1 }}
        color="error"
        onClick={() => setMergeResolve((prev) => !prev)}
      />
    </Stack>
  ) : mergeObjectCell[type] === 1 ? (
    <Stack direction="row" alignItems="center" justifyContent="center">
      <Chip
        label={
          <Stack direction="row" justifyContent="center" alignItems="center">
            <KeyboardDoubleArrowLeftIcon></KeyboardDoubleArrowLeftIcon>
            <Typography variant="subtitle2" sx={{ mx: 1 }}>
              merge
            </Typography>
            <KeyboardDoubleArrowLeftIcon></KeyboardDoubleArrowLeftIcon>
          </Stack>
        }
        sx={{ m: 1 }}
        color="warning"
        onClick={() => mergeAction(indexRow, indexCell, type)}
      />
    </Stack>
  ) : null;
};

export default MergeActionButton;
